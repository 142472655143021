import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer, CardContent, Button, CircularProgress, Box, ListSubheader,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {useListEmployeesQuery} from "../store/employee/employeeApi";
import {useCreatePracticeMutation, useEditPracticeMutation, useGetPracticeQuery} from "../store/practice/practiceApi";
import PageTitle from "../components/PageTitle";

export default function EditPracticePage() {
    const {practiceId, groupId} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const {data} = useListEmployeesQuery();
    const practiceData = useGetPracticeQuery({practiceId});
    const [copyOfData, setCopyOfData] = useState(null);
    const [editPracticeRequest] = useEditPracticeMutation();
    const [canSave, setCanSave] = useState(false);
    const [request, setRequest] = useState({
        practiceId: 0,
        practiceKind: 1,
        practiceType: '',
        controlType: 1,
        orderNumber: '',
        commission: [],
        timeSpans: [],
        defendFrom: null,
        defendTo: null
    });

    useEffect(() => {
        if (data === null || data === undefined)
            return;

        setCopyOfData(copyObject(data));
        refillRequest();
    }, [data, practiceData]);
    
    function refillRequest() {
        if (request.practiceId > 0)
            return;
        
        const practice = practiceData?.data?.practice;
        if (practice === null || practice === undefined)
            return;
        
        request.practiceId = practiceId;
        request.practiceKind = practice.practiceKind;
        request.practiceType = practice.practiceType;
        request.controlType = practice.controlType;
        request.orderNumber = practice.orderNumber;
        request.defendFrom = fromRuDateToInternationalDateObject(toDateString(practice.defenceFrom));
        request.defendTo = fromRuDateToInternationalDateObject(toDateString(practice.defenceTo));
        request.commission = practice?.commission?.map((item) => copyObject(item)) ?? [];
        request.timeSpans = practice?.timeSpans?.map((item) => {
            console.log(`toDate: ${item.to}`)
            return {
                from: fromRuDateToInternationalDateObject(item.from),
                to: fromRuDateToInternationalDateObject(item.to)
            }
        }) ?? [];
        
        console.log(request);
        applyVisualChanges();
    }

    function toDateString(date) {
        const result = new Date(date.toString()).toLocaleDateString("ru-RU");
        console.log(`toDateString = ${result}`);
        
        return result;
    }
    
    function fromRuDateToInternationalDateObject(date) {
        // костыль. Придумать элегантное решение
        const parts = date.toString().split('.');
        const internationalDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;
        console.log(`${date} was transformed into ${internationalDateString}`)
        return internationalDateString;
    }

    function checkIfCanSave() {
        const incorrectTimespan = request.timeSpans.find((item) => {
            return new Date(item.from) > new Date(item.to);
        })

        if (incorrectTimespan !== null && incorrectTimespan !== undefined){
            console.log('Incorrect timespan');
            console.log(incorrectTimespan);
        }

        const practiceTypeIsOk = request.practiceType !== null && request.practiceType?.length > 0;
        const orderNumberIsOk = request.orderNumber !== null && request.orderNumber?.length > 0;
        const commissionIsOk = request.commission?.length > 0;
        const defenceIsOk = new Date(request.defendFrom) < new Date(request.defendTo);
        console.log(`PracticeType: ${practiceTypeIsOk}; Order Num: ${orderNumberIsOk}; Commission: ${commissionIsOk}; Defence: ${defenceIsOk}; Timespans: ${(incorrectTimespan === null || incorrectTimespan === undefined)}; Timespans length: ${request.timeSpans.length >= 1}`);

        setCanSave(practiceTypeIsOk
            && orderNumberIsOk
            && commissionIsOk
            && request.timeSpans.length >= 1
            && (incorrectTimespan === null || incorrectTimespan === undefined)
            && defenceIsOk);
    }

    function applyVisualChanges() {
        setRefresh(!refresh);
        checkIfCanSave();
    }

    function copyObject(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    function addCommission(employee) {
        request.commission.push(copyObject(employee));
        setSelectedEmployee(employee);
        checkIfCanSave();
    }

    function removeCommission(employee) {
        const index = request.commission.indexOf(employee);
        if (index > -1) {
            request.commission.splice(index, 1);
        }

        checkIfCanSave();
    }

    function addNewTimeSpan() {
        request?.timeSpans?.push({
            from: formatDate(new Date()),
            to: formatDate(new Date())
        });

        applyVisualChanges();
    }

    function removeTimeSpan(timeSpan) {
        // request.timeSpans = request.timeSpans.filter((item) => item !== timeSpan);
        if (request.timeSpans.length <= 1)
            return;

        const index = request.timeSpans.indexOf(timeSpan);
        if (index > -1) {
            request.timeSpans.splice(index, 1);
        }

        applyVisualChanges();
    }

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const handleSave = async () => {
        setIsSaving(true);

        await editPracticeRequest({data: request}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                navigate(`/faculty-practices-for-group/${groupId}`, {
                    replace: true
                });
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });

                setIsSaving(false);
            }
        });
    };

    return (
        <GeneralLayout>
            <PageTitle title={`Редактировать практику`} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress /> : <></>}
                            Редактировать практику
                        </Typography>
                    </Stack>

                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Stack direction={"row"} spacing={3}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Вид практики</InputLabel>
                                        <Select size={"small"}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={request.practiceKind}
                                                onChange={(event) => {request.practiceKind = event.target.value; applyVisualChanges()}}
                                                label="Вид практики"
                                        >
                                            <MenuItem value={1}>Учебная</MenuItem>
                                            <MenuItem value={2}>Производственная</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <TextField label={"Тип практики"}
                                                   size={"small"}
                                                   value={request.practiceType}
                                                   onChange={(event) => {request.practiceType = event.target.value; applyVisualChanges()}}/>
                                    </FormControl>
                                </Stack>

                                <Stack direction={"row"} spacing={3}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Тип контроля</InputLabel>
                                        <Select
                                            size={"small"}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={request.controlType}
                                            onChange={(event) => {request.controlType = event.target.value; applyVisualChanges()}}
                                            label="Тип контроля"
                                        >
                                            <MenuItem value={1}>Зачёт</MenuItem>
                                            <MenuItem value={2}>Зачёт с оценкой</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <TextField label={"Приказ на практику"}
                                                   size={"small"}
                                                   value={request.orderNumber}
                                                   onChange={(event) => {request.orderNumber = event.target.value; applyVisualChanges()}}/>
                                    </FormControl>
                                </Stack>

                                <Stack direction={"row"} spacing={0} alignItems={"center"}>
                                    <Typography>Сроки проведения: </Typography>
                                    <Button size={"small"} onClick={() => {addNewTimeSpan()}}><AddIcon /></Button>
                                </Stack>

                                {request?.timeSpans.map((ts) => {
                                    return <Stack direction={"row"} spacing={3}>
                                        <FormControl fullWidth>
                                            <Typography fontSize={"small"}>Дата начала</Typography>
                                            <TextField type={"date"}
                                                       size={"small"}
                                                       value={ts.from}
                                                       onChange={(event) => {ts.from = event.target.value; applyVisualChanges()}}/>
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <Typography fontSize={"small"}>Дата окончания</Typography>
                                            <TextField type={"date"}
                                                       size={"small"}
                                                       value={ts.to}
                                                       onChange={(event) => {ts.to = event.target.value; applyVisualChanges()}}/>
                                        </FormControl>

                                        <Button color={"error"}
                                                size={"small"}
                                                onClick={() => { removeTimeSpan(ts) }}><RemoveCircleIcon /></Button>
                                    </Stack>
                                })}

                                <Stack direction={"row"} spacing={0} alignItems={"center"}>
                                    <Typography>Сроки защиты: </Typography>
                                </Stack>

                                <Stack direction={"row"} spacing={3}>
                                    <FormControl fullWidth>
                                        <Typography fontSize={"small"}>Дата начала</Typography>
                                        <TextField type={"date"}
                                                   size={"small"}
                                                   value={request.defendFrom}
                                                   onChange={(event) => {request.defendFrom = event.target.value; applyVisualChanges()}}/>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <Typography fontSize={"small"}>Дата окончания</Typography>
                                        <TextField type={"date"}
                                                   size={"small"}
                                                   value={request.defendTo}
                                                   onChange={(event) => {request.defendTo = event.target.value; applyVisualChanges()}}/>
                                    </FormControl>
                                </Stack>

                                <Typography>Состав комиссии по защите отчетов:</Typography>

                                <ol>
                                    {request.commission.map((item) => {
                                        return <li>{item.lastName} {item.firstName} {item.middleName}
                                            <Button color={"error"}
                                                    size={"small"}
                                                    onClick={() => {removeCommission(item)}}><RemoveCircleIcon/></Button>
                                        </li>
                                    })}
                                </ol>

                                <FormControl fullWidth>
                                    <InputLabel id="comission-label">Добавить члена комиссии</InputLabel>
                                    <Select
                                        labelId="comission-label"
                                        id="comission-select"
                                        label="Добавить члена комиссии"
                                        value={selectedEmployee}
                                        onChange={(event, node) => {console.log('change'); addCommission(event.target.value)}}
                                    >
                                        {copyOfData?.data.map((ce) => {
                                            const tempArr = [];
                                            tempArr.push((<ListSubheader>{ce.chairName}</ListSubheader>));
                                            tempArr.push(ce.employees.sort((e1, e2) => {
                                                return e1.lastName > e2.lastName ? 1 : -1;
                                            }).map((e) => {
                                                return <MenuItem key={e.employeeId} value={e}>{e.lastName} {e.firstName} {e.middleName}</MenuItem>
                                            }));

                                            return tempArr;
                                        })}
                                    </Select>
                                </FormControl>

                                <Stack justifyContent="space-between">
                                    {
                                        !isSaving
                                            ? <Button variant="contained"
                                                      disabled={data === null || data === undefined || !canSave}
                                                      onClick={handleSave}>Сохранить</Button>
                                            : <CircularProgress/>
                                    }
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}