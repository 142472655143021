import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {prepareAuthHeaders} from "../../utils/prepareAuthHeaders";

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/User',
        prepareHeaders: prepareAuthHeaders }),
    keepUnusedDataFor: 1,
    endpoints: (builder) => ({
        getUserDetails: builder.query({
            query: () => ({
                url: `/UserDetails`,
                method: 'GET',
            }),
        }),
        test: builder.query({
            query: () => ({
                url: `/Test`,
                method: 'GET',
            }),
        }),
    }),
});

export const { 
    useGetUserDetailsQuery,
    useTestQuery
} = userApi;
