import { CloseRounded } from "@mui/icons-material";
import {
	Button,
	Grid,
	IconButton,
	Select,
	TextField,
	MenuItem,
	FormControl,
	InputLabel,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

function DateModal(props) {
	const [event, setEvent] = useState({
		theme: "",
		lessonDate: '2024-01-01',
		pairNumber: "1"
	});

	useEffect(() => {
		if (props.event) {
			// const newEvent = JSON.parse(
			// 	JSON.stringify({
			// 		theme: props.event.theme,
			// 		date: null,
			// 		pair: props.event.pair,
			// 		dayId: props.event.dayId,
			// 		groupId: props.event.groupId,
			// 	})
			// );
			// newEvent.date = dayjs(new Date(props.event.date));
			// setEvent(newEvent);
			setEvent(props.event)
			console.log('event is', event)
		}
	}, [props.event]);

	const closeModal = () => {
		props.closeHandler();
	};

	const updatePair = (e, newVal) => {
		setEvent((prevEvent) => ({ ...prevEvent, pairNumber: newVal.props.value }));
	};

	const updateTheme = (e) => {
		setEvent((prevEvent) => ({ ...prevEvent, theme: e.target.value }));
	};

	const updateDate = (e) => {
		const newEvent = { ...event };
		newEvent.lessonDate = e;
		setEvent(newEvent);
	};

	const save = () => {
		props.saveHandler(event);
	};

	return (
		<div
			className={props.open ? "modal-wrapper open" : "modal-wrapper"}
			id="event"
		>
			<div className="modal">
				<div className="modal-content">
					<div className="modal-header" style={{"display": "flex", "justify-content": "space-between"}}>
						<div className="name">Редактирование занятия</div>
						<IconButton onClick={closeModal}>
							<CloseRounded />
						</IconButton>
					</div>
					<Grid container columnSpacing={2} rowSpacing={3}>
						<Grid item md={12}>
							<TextField
								onChange={updateTheme}
								value={event.theme}
								sx={{ width: "100%" }}
								variant="standard"
								disabled
								label="Тема занятия"
							/>
						</Grid>
						<Grid item md={8}>
							<LocalizationProvider
								dateAdapter={AdapterDayjs}
								adapterLocale="ru"
							>
								<DatePicker
									value={dayjs(new Date(event.lessonDate))}
									format="D MMMM YYYY г."
									variant="standard"
									label="Дата занятия"
									disabled
									sx={{ width: "100%" }}
									onChange={updateDate}
									slotProps={{
										textField: {
											variant: "standard",
										},
									}}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid item md={4}>
							<FormControl
								variant="standard"
								sx={{ width: "100%" }}
							>
								<InputLabel>Пара</InputLabel>
								<Select
									disabled
									value={event.pairNumber}
									onChange={updatePair}
								>
									<MenuItem value="1">1 пара</MenuItem>
									<MenuItem value="2">2 пара</MenuItem>
									<MenuItem value="3">3 пара</MenuItem>
									<MenuItem value="4">4 пара</MenuItem>
									<MenuItem value="5">5 пара</MenuItem>
									<MenuItem value="6">6 пара</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</div>
				<div className="modal-footer">
					<span />
					<Button variant="text"
							disabled
							onClick={save}>
						Сохранить
					</Button>
				</div>
			</div>
		</div>
	);
}

export default DateModal;
