/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, no-restricted-syntax, guard-for-in, react/self-closing-comp */
import {
    Button,
    Card, CardContent, Checkbox, CircularProgress, Container,
    Popover,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, Tooltip,
    Typography
} from "@mui/material";
import Scrollbar from "../scrollbar/Scrollbar";
import IconButton from "@mui/material/IconButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import JournalGradeSmall from "../../sections/@dashboard/journal/JournalGradeSmall";
import PropTypes from "prop-types";
import {Fragment, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    useGetJournalByGroupQuery, usePostEditExtraGradeForStudentMutation,
    usePostEditGradeForStudentMutation,
    usePostSetAttendanceForStudentMutation, useSetCommentForStudentLessonMutation
} from "../../store/journal/journalApi";
import moment from "moment";
import {CloseSharp} from "@mui/icons-material";
import ValueModal from "../../pages/disciplinegroupjournal/components/value_modal";
import DateModal from "../../pages/disciplinegroupjournal/components/date_modal";
import FormControlLabel from "@mui/material/FormControlLabel";
import PageHeader from "../pageHeader";

JournalComponent.propTypes = {
    groupFlowBindingId: PropTypes.number,
    virtualGroupId: PropTypes.number,
    groupName: PropTypes.string
}

export default function JournalComponent(props) {
    // new block
    // Инициализация
    const myTable = useRef(null);
    const [headerData, setHeaderData] = useState([]);
    const [footerData, setFooterData] = useState([]);
    const [students, setStudents] = useState([]);
    const [tooltips, setTooltips] = useState(true);
    const [isStudentOpen, setIsStudentOpen] = useState(false);
    const [day, setDay] = useState(null);
    const [student, setStudent] = useState(null);
    const [event, setEvent] = useState({
        pair: 1,
        groupId: null,
        dayId: null,
        date: null,
        theme: "",
    });
    const [eventOpen, setEventOpen] = useState(false);
    // end of new block
    const navigate = useNavigate();
    const [groupName, setGroupName] = useState('');
    const [attendancePercentage, setAttendancePercentage] = useState(100);
    const [gradePopoverOpen, setGradePopoverOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [attendancePopoverOpen, setAttendancePopoverOpen] = useState(false);
    const [lessonPopoverOpen, setLessonPopoverOpen] = useState(false);
    const [attAnchorEl, setAttAnchorEl] = useState(null);
    const [lessonAnchorEl, setLessonAnchorEl] = useState(null);
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [clickedOnGradeType, setClickedOnGradeType] = useState(0);
    const {data} = useGetJournalByGroupQuery({
        groupFlowBindingId: props.groupFlowBindingId,
        virtualGroupId: props.virtualGroupId
    });
    const [refresh, setRefresh] = useState(false);
    const [lessons, setLessons] = useState([]);
    const [copyOfLessons, setCopyOfLessons] = useState([]);
    const [setAttendanceForStudent] = usePostSetAttendanceForStudentMutation();
    const [setCommentForStudent] = useSetCommentForStudentLessonMutation();
    const [editGradeForStudent] = usePostEditGradeForStudentMutation();
    const [editExtraGradeForStudent] = usePostEditExtraGradeForStudentMutation();
    const isEmployee = localStorage.getItem("roles").split(',').find((item) => item === "employee") !== undefined;
    
    useEffect(() => {
        if (data === null || data === undefined)
            return;

        fulfillTable();

        setStudents(copyObject(data?.students ?? []))

        const copyOfData = data.students.map((item) => copyStudent(item));
        setCopyOfLessons(copyOfData);
    }, [data]);
    
    function fulfillTable() {
        const student = data?.students?.find((item) => item.lessons?.length > 0);
        if (student !== null && student !== undefined && student.lessons.length !== 0) {
            const tempLessons = copyObject(student.lessons ?? []);
            setLessons(tempLessons);
            // setHeaderData()
            setHeaderData(divideLessonsByMonths(tempLessons))
            setFooterData(calculatePercentageOfAttendance(data.students, tempLessons))
        }
    }

    function calculatePercentageOfAttendance(allStudents, headerLessons) {
        const result = [];
        headerLessons.forEach((headerLesson) => {
            const lessonsOfDate = [];
            allStudents.forEach((student) => {
                const lessonOfThisDate = student.lessons.find((lesson) => lesson.lessonDate === headerLesson.lessonDate && lesson.pairNumber === headerLesson.pairNumber);
                if (lessonOfThisDate) {
                    lessonsOfDate.push(lessonOfThisDate.attended);
                }
            })

            if (lessonsOfDate.length === 0)
                result.push(0);
            else
                result.push(100 - (lessonsOfDate.filter((item) => !item).length * 100 / lessonsOfDate.length).toFixed(2))
        })

        return result;
    }

    function getGradeTypeName(typeId) {
        switch (typeId) {
            case 1:
                return 'Тестирование';
            case 2:
                return 'Лабораторная работа';
            case 3:
                return 'Контрольная работа';
            case 4:
                return 'Расчётно-графическая работа';
            case 5:
                return 'Домашняя работа';
            case 6:
                return 'Другое';
            default:
                return typeId;
        }
    }

    function divideLessonsByMonths(array) {
        // const groupped = Object.groupBy(array, (item) => moment(item.lessonDate).month())
        
        const groupped = array.reduce((r, a) => {
            r[moment(a.lessonDate).month()] = r[moment(a.lessonDate).month()] || [];
            r[moment(a.lessonDate).month()].push(a);
            return r;
        }, Object.create(null));
        
        const divided = [];
        for (const monthNum in groupped) {
            const monthName = getMonthName(parseInt(monthNum, 10) + 1)
            divided.push({
                group: [{
                    title: monthName,
                    content: groupped[monthNum]
                }]
            })
        }

        console.log(divided)
        return divided
    }

    function getMonthName(num) {
        switch (num) {
            case 1:
                return 'Январь';
            case 2:
                return 'Февраль';
            case 3:
                return 'Март';
            case 4:
                return 'Апрель';
            case 5:
                return 'Май';
            case 6:
                return 'Июнь';
            case 7:
                return 'Июль';
            case 8:
                return 'Август';
            case 9:
                return 'Сентябрь';
            case 10:
                return 'Октябрь';
            case 11:
                return 'Ноябрь';
            case 12:
                return 'Декабрь';
            default:
                return '';
        }
    }

    // block of new functions
    // Сохранение студента
    const updateStudent = (newStudent, lesson) => {
        setIsStudentOpen(false);
        // setStudent(student);
        // let newStudents = [...students];
        // newStudents = newStudents.map((s) => {
        //     if (s.id === student.id)
        //         return newStudent;
        //
        //     return s;
        // });
        // setStudents(newStudents);

        const updateAttendanceRequest = {
            lessonStudentDetailsId: lesson.lessonStudentDetailsId,
            value: lesson.attended
        };

        const updateGradesRequest = {
            grades: lesson.grades?.map((item) => {
                return {
                    lessonStudentGradeId: item.lessonStudentGradeId,
                    grade: item.grade,
                    gradeType: item.gradeType,
                    deleted: item.deleted,
                    lessonStudentDetailsId: item.lessonStudentDetailsId
                }
            })
        };

        const updateCommentRequest = {
            lessonStudentDetailsId: lesson.lessonStudentDetailsId,
            comment: lesson.comment
        }

        setAttendanceForStudent(updateAttendanceRequest).unwrap()
            .then(() => {
                students.forEach((item) => {
                    const search = item.lessons.find((les) => les.lessonStudentDetailsId === lesson.lessonStudentDetailsId);
                    if (search !== undefined) {
                        search.attended = lesson.attended
                    }
                })

                setCommentForStudent(updateCommentRequest).unwrap()
                    .then(() => {
                        students.forEach((item) => {
                            // const search = item.lessons.find((les) => les.lessonStudentDetailsId === lesson.lessonStudentDetailsId);
                            // if (search !== undefined) {
                            //     search.comment = lesson.comment
                            // }
                        })

                        editGradeForStudent(updateGradesRequest).unwrap()
                            .then((response) => {
                                students.forEach((item) => {
                                    const search = item.lessons.find((les) => les.lessonStudentDetailsId === lesson.lessonStudentDetailsId);
                                    if (search !== undefined) {
                                        search.grades = response.grades
                                    }
                                })

                                applyVisualChanges()
                            })
                            .catch((e) => alert('Произошла ошибка'))
                    }).catch((e) => alert('Произошла ошибка'))
            }).catch((e) => alert('Произошла ошибка'))
    };

    // Открытие студента
    const openStudent = (studentData, lessonData) => {
        setStudent(studentData);
        setDay(lessonData);
        
        console.log(`open`, lessonData)
        setTimeout(() => {
            setIsStudentOpen(true);
        });
    };

    // Закрытие студента
    const closeStudent = (defaultStudent) => {
        setIsStudentOpen(false);
        setStudent(defaultStudent);
    };

    // Получение оценок
    const getValues = (day, index) => {
        if (day?.grades?.length === 0) {
            return (
                <span key={index} className="fogged">
					–
				</span>
            );
        }

        return (
            <span key={index}>
					{day.grades?.map((value, index2) => {
                        return (
                            <Fragment key={index2}>
                                <Tooltip
                                    title={tooltips ? getGradeTypeName(value.gradeType) : ""}
                                    placement="top"
                                >
                                    <span>{value.grade}</span>
                                </Tooltip>
                                {index2 < day.grades.length - 1 ? " / " : ""}
                            </Fragment>
                        );
                    })}
				</span>
        );
    };

    // Открытие урока
    function openEvent(lesson) {
        // const el = e.target;
        console.log('open event', lesson)
        // const day = parseInt(el.dataset.day, 10);
        // const month = parseInt(el.dataset.group, 10);
        // const lessonData = headerData[month].group[0].content[day];
        // lessonData.dayId = day;
        // lessonData.groupId = month;
        setEvent(lesson);

        setTimeout(() => {
            setEventOpen(true);
        });
    };

    // Закрытие урока
    const closeEvent = (defaultEvent) => {
        setEvent(defaultEvent);
        setEventOpen(false);
    };

    // Сохранение урока
    const saveEvent = (event) => {
        const day = event.date.$D;
        const month = [
            "jan",
            "feb",
            "mar",
            "apr",
            "may",
            "jun",
            "july",
            "aug",
            "sept",
            "oct",
            "nov",
            "dec",
        ][event.date.$M];
        const year = event.date.$y;

        const newHeaderData = [...headerData];
        const group = newHeaderData[event.groupId];
        const dayEntry = group.group[0].content[event.dayId];
        dayEntry.day = day;
        dayEntry.date = `${day} ${month} ${year}`;
        dayEntry.pair = parseInt(event.pair, 10);

        setHeaderData(headerData);
        setEventOpen(false);
    };

    // Подсветка строки
    const highlight = (e) => {
        e.target.classList.add("extra-highlight");
        const row = e.target.parentElement;
        const table = row.parentElement.parentElement;
        const rowIndex = e.target.cellIndex;

        // Row
        row.querySelectorAll("td").forEach((cell) => {
            cell.classList.add("highlight");
        });

        // Column
        table.querySelectorAll("tbody tr").forEach((row) => {
            const col = row.querySelectorAll("td")[rowIndex - 2];
            col?.classList.add("highlight");
        });
    };

    // Сброс подсветки строки
    const resetHighlight = (e) => {
        myTable.current.querySelectorAll("td").forEach((cell) => {
            cell.classList?.remove("highlight");
            cell.classList?.remove("extra-highlight");
        });
    };

    // Отображение тултипов
    const handleCheck = (e) => {
        setTooltips(e.target.checked);
    };

    // enf of block of new functions

    function copyLesson(input) {
        return {
            lessonId: input.lessonId,
            lessonDate: input.lessonDate,
            theme: input.theme,
            pairNumber: input.pairNumber,
            attended: input.attended,
            grade: input.grade,
            extraGrade: input.extraGrade,
            extraGradeType: input.extraGradeType,
            lessonStudentDetailsId: input.lessonStudentDetailsId,
            isSaving: false
        };
    }

    function copyStudent(input) {
        return copyObject(input);
        // return {
        //   studentId: input.studentId,
        //   lastName: input.lastName,
        //   firstName: input.firstName,
        //   middleName: input.middleName,
        //   lessons: input.lessons?.map((item) => copyLesson(item)) ?? [],
        //   certificatesOfSkipping: input.certificatesOfSkipping !== null && input.certificatesOfSkipping !== undefined
        //     ? copyObject(input.certificatesOfSkipping)
        //     : []
        // };
    }

    function certificateTypeName(id) {
        switch (id) {
            case 0:
                return 'Справка';
            case 1:
                return 'Распоряжение деканата';
            case 2:
                return 'Заявление';
            case 3:
                return 'График дежурств';
            default:
                return '-';
        }
    }

    function getCertificateOfSkipping(student, date, pair) {
        if (student.certificatesOfSkipping === null
            || student.certificatesOfSkipping === undefined
            || student.certificatesOfSkipping.length === 0) {
            return null;
        }

        const certificate = student.certificatesOfSkipping.find((item) => {
            if (date === item.fromDate && date === item.toDate) {
                return pair >= item.fromPair && pair <= item.toPair;
            }

            if (date === item.fromDate) {
                return pair >= item.fromPair;
            }

            if (date === item.toDate) {
                return pair <= item.toPair;
            }

            return item.fromDate <= date && item.toDate >= date;
        });

        return certificate ?? null;
    }

    function copyObject(source) {
        if (source === null || source === undefined)
            return {};

        return JSON.parse(JSON.stringify(source));
    }

    function applyVisualChanges() {
        setRefresh(!refresh);
    }

    function removeLesson() {
        alert('функция находится в разработке');
        // TODO: send a request to the server
        // you can take a lesson entity from the 'selectedLesson' object

        // TODO: after sending the data to the server need to reload students array from the server again
        closeLessonPopover();
    }

    function editLesson() {
        alert('Функция находится в разработке');
        /* navigate('/journaledit',
            {
                replace: false,
                state: {
                    bindingId: selectedLesson?.lessonId
                }
            }); */
    }

    async function changeGrade(grade) {
        // type = 0 = primary grade
        // type = 1 = extra grade
        if (!isEmployee)
            return;

        selectedLesson.isSaving = true;
        if (clickedOnGradeType === 0) {
            await editGradeForStudent({
                lessonStudentDetailsId: selectedLesson.lessonStudentDetailsId,
                grade
            }).unwrap()
                .then(onSuccess);
        } else if (clickedOnGradeType === 1) {
            await editExtraGradeForStudent({
                lessonStudentDetailsId: selectedLesson.lessonStudentDetailsId,
                grade
            }).unwrap()
                .then(onSuccess);
        }

        function onSuccess(fulfilled) {
            selectedLesson.isSaving = false;
            applyVisualChanges();
            if (!fulfilled.success) {
                alert(fulfilled.errors[0]);
                return;
            }

            if (clickedOnGradeType === 0) {
                selectedLesson.grade = grade;
            } else if (clickedOnGradeType === 1) {
                selectedLesson.extraGrade = grade;
            }

            setGradePopoverOpen(false);
            setAnchorEl(null);
        }
    }

    async function changeAttendance(value) {
        if (!isEmployee)
            return;

        selectedLesson.isSaving = true;
        console.warn(selectedLesson);
        await setAttendanceForStudent({
            lessonStudentDetailsId: selectedLesson.lessonStudentDetailsId,
            value
        }).unwrap()
            .then((fulfilled) => {
                selectedLesson.isSaving = false;
                applyVisualChanges();
                if (!fulfilled.success) {
                    alert(fulfilled.errors[0]);
                    return;
                }

                selectedLesson.attended = value;
                applyVisualChanges();

                // TODO: send new grade to the server

                setAttendancePopoverOpen(false);
                setAttAnchorEl(null);
            });
    }

    function closeAttPopover() {
        setAttendancePopoverOpen(false);
        setAttAnchorEl(null);
    }

    function closeLessonPopover() {
        setLessonPopoverOpen(false);
        setLessonAnchorEl(null);
    }

    function handlePopoverClick(event, lesson, gradeType) {
        if (!isEmployee)
            return;

        setAnchorEl(anchorEl ? null : event.currentTarget);
        setGradePopoverOpen(!gradePopoverOpen);
        setSelectedLesson(lesson);
        setClickedOnGradeType(gradeType);
    }

    function handleAttPopoverClick(event, lesson, student) {
        if (!isEmployee)
            return;

        setAttAnchorEl(attAnchorEl ? null : event.currentTarget);
        setAttendancePopoverOpen(!attendancePopoverOpen);
        setSelectedLesson(lesson);
        setSelectedStudent(student);
    }

    function handleLessonPopoverClick(event, lesson) {
        if (!isEmployee)
            return;

        setLessonAnchorEl(attAnchorEl ? null : event.currentTarget);
        setLessonPopoverOpen(!lessonPopoverOpen);
        setSelectedLesson(lesson);
    }

    function toDateString(date) {
        if (date === null)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    function getCertificateOfSkippingStudentDetailsElement(student, lessonDate, pair) {
        if (!student || !lessonDate || !pair)
            return <></>

        const cert = getCertificateOfSkipping(student, lessonDate, pair);

        return cert !== null
            ? <Typography
                color={'orange'}>Имеется {certificateTypeName(cert.documentType).toLowerCase()} с {toDateString(cert.fromDate)} ({cert.fromPair} пара)
                по {toDateString(cert.toDate)} ({cert.toPair} пара)
                <br/>Комментарий: {cert.comment ?? '-'}</Typography>
            : <></>
    }

    function getAverageGrade(student) {
        let numberOfGrades = 0;
        let sumOfGrades = 0;
        student.lessons.forEach((lesson) => {
            lesson.grades?.forEach((lessonGrade) => {
                numberOfGrades += 1;
                sumOfGrades += lessonGrade.grade;
            })
        });

        return numberOfGrades === 0 ? '-' : (sumOfGrades / numberOfGrades).toFixed(2);
    }

    function getNumberOrSkips(student) {
        const skippedLessons = student.lessons.filter((lesson) => !lesson.attended);
        return skippedLessons.length;
    }

    function totalAverageGrade() {
        if (students === null || students === undefined)
            return '';

        const averages = students?.map((student) => getAverageGrade(student))
            .filter((item) => item !== '-');
        let sumOfGrades = 0;

        averages.forEach((item) => {
            sumOfGrades += parseInt(item, 10);
        })

        if (averages.length === 0)
            return '';

        return sumOfGrades / averages.length;
    }

    function totalAttendance() {
        let sum = 0;
        footerData.forEach((item) => {
            sum += item;
        })

        if (footerData.length === 0)
            return 0;

        return sum / footerData.length;
    }

    return (<>
        <Card>
            <CardContent>
                <div className="card-header">
                    <div className="title">
                        <h2>{props.groupName} ({totalAttendance().toFixed(2)}%)</h2>
                    </div>
                    <div className="extra">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={tooltips}
                                    onClick={handleCheck}
                                />
                            }
                            label="Подсказки"
                        />
                    </div>
                </div>
                <div className="table-wrapper">
                    <table className="complex-table" ref={myTable}>
                        <colgroup key={'number'}>
                            <col className={'month'}/>
                            <col className={'month'}/>
                        </colgroup>
                        {headerData?.map((col, index) => {
                            return (
                                <colgroup key={index}>
                                    {col.group?.map(
                                        (item, index) => {
                                            const className =
                                                item.content === null ? "num" : "month";
                                            return (
                                                <col
                                                    key={index}
                                                    className={className}
                                                    span={item.content.length}
                                                />
                                            );
                                        }
                                    )}
                                </colgroup>
                            );
                        })}
                        <thead>
                        <tr>
                            <th key="numbercol" rowSpan={2}>№</th>
                            <th key="fullnamecol" rowSpan={2}>Студент</th>
                            {headerData?.map((col, index) => {
                                return col.group?.map(
                                    (group, index2) => {
                                        if (
                                            group.content
                                                .length === 0
                                        ) {
                                            return (
                                                <th
                                                    key={index2}
                                                    rowSpan={2}>
                                                    {
                                                        group.title
                                                    }
                                                </th>
                                            );
                                        }

                                        return (
                                            <th key={index2}
                                                colSpan={group.content.length}>
                                                {
                                                    group.title
                                                }
                                            </th>
                                        );
                                    }
                                );
                            })}

                            <th key="avggradecol" rowSpan={2}>Ср балл</th>
                            <th key="totalavggradecol" rowSpan={2}>Проп. занятий</th>
                        </tr>
                        <tr>
                            {headerData?.map((col, index) => {
                                return col.group?.map(
                                    (group, index2) => {
                                        return group.content?.map(
                                            (cell, index3) => {
                                                return (
                                                    <th
                                                        onClick={() => openEvent(cell)}
                                                        data-group={
                                                            index
                                                        }
                                                        data-day={
                                                            index3
                                                        }
                                                        key={
                                                            index3
                                                        }
                                                    >
                                                        {moment(cell.lessonDate).date()}
                                                    </th>
                                                );
                                            }
                                        );
                                    }
                                );
                            })}
                        </tr>
                        </thead>
                        <tbody>
                        {students?.map((student, index) => {
                            return (
                                <tr key={index}>
                                    <th>{index + 1}</th>
                                    <th>{student?.lastName} {student?.firstName} {student?.middleName}</th>
                                    {student?.lessons?.map(
                                        (lesson, index2) => {
                                            return (
                                                <td
                                                    data-student={
                                                        student.id
                                                    }
                                                    key={index2}
                                                    data-day={
                                                        index2
                                                    }
                                                    className={!lesson.attended ? 'absent' : getCertificateOfSkipping(student, lesson.lessonDate, lesson.pairNumber) !== null ? 'soft-absent' : ''}
                                                    onMouseEnter={
                                                        highlight
                                                    }
                                                    onMouseLeave={
                                                        resetHighlight
                                                    }
                                                    onKeyDown={(event) => console.log('заглушка')}
                                                    onClick={(e) => openStudent(student, lesson)}
                                                >
                                                    {getValues(
                                                        lesson,
                                                        index2
                                                    )}
                                                </td>
                                            );
                                        }
                                    )}
                                    <th className="summary">
                                        {getAverageGrade(student)}
                                    </th>
                                    <th className="summary">
                                        {getNumberOrSkips(student)}/{student.lessons.length}
                                    </th>
                                </tr>
                            );
                        })}
                        </tbody>
                        <tfoot>
                        <tr>
                            <th key={'empty'} colSpan={1}></th>
                            <th key={'attendancepercentagelabelcol'} colSpan={1}>
                                <span>
                                    Посещаемость %
                                </span>
                            </th>
                            {footerData.map((entry, index) => {
                                return (
                                    <th key={index} colSpan={1}>
                                        <span>
                                            {entry.toFixed(2)}%
                                        </span>
                                    </th>
                                );
                            })}
                            <th colSpan={1}>{totalAverageGrade()}</th>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </CardContent>
        </Card>
        <ValueModal
            day={day}
            student={student}
            open={isStudentOpen}
            saveHandler={updateStudent}
            closeHandler={closeStudent}
        />
        <DateModal
            event={event}
            open={eventOpen}
            saveHandler={saveEvent}
            closeHandler={closeEvent}
        />
    </>);
}