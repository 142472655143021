import {Box, Button, CircularProgress, Grid, Link, Paper, Stack, Typography} from "@mui/material";
import {PrintOutlined} from "@mui/icons-material";
import PropTypes from "prop-types";
import {styled} from "@mui/material/styles";
import {useGetStatementInfoQuery} from "../../../store/statement/statementApi";
import {useState} from "react";

PracticeStatementInfoBlock.propTypes = {
    statement: PropTypes.object,
    printEnabled: PropTypes.bool
}

export default function PracticeStatementInfoBlock(props){
    console.log(props.statement);
    const [statementIsLoading, setStatementIsLoading] = useState(false);
    const Item = styled(Paper)(({theme}) => ({
        backgroundColor: '#F9FAFB',
        ...theme.typography.body2,
        textAlign: 'start',
    }));

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function toDateString(date){
        return new Date(date).toLocaleDateString("ru-RU");
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    function nameOfPracticeKind(practiceKind) {
        switch (practiceKind)
        {
            case 0: return 'учебная';
            case 1: return 'производственная';
            default: return '';
        }
    }

    function downloadStatement() {
        setStatementIsLoading(true);
        fetch(`/Reports/PracticeStatement?statementId=${props.statement.practiceStatementId}`,
            {
                method: "GET",
                headers: { "semesterid": localStorage.getItem("selectedSemesterId"), "Content-Type": "application/json", "Authorization": `Bearer ${localStorage.getItem('token')}`}
            }).then(response => {
            setStatementIsLoading(false);
            return response.blob();
        }).then(response => {
            const url = window.URL.createObjectURL(
                new Blob([response]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `Ведомость по практике.pdf`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        })
    }

    return (
        <>
            <Stack direction="row" spacing={4}>
                <Typography variant="h5">Информация о ведомости</Typography>
                {props.printEnabled
                    ? (<Button startIcon={statementIsLoading ? <CircularProgress size={15} /> : <PrintOutlined /> } 
                               variant="outlined" 
                               color="primary"
                               onClick={() => downloadStatement()}>Печать</Button>)
                    : (<></>)}
            </Stack>

            <br/>
            <Box sx={{flexGrow: 1}}>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <Item>
                            <Stack spacing={3}>
                                <Typography><strong>Вид, тип практики: </strong>{nameOfPracticeKind(props.statement?.practiceKind)}, {props.statement?.practiceType}</Typography>
                                <Typography><strong>Группа: </strong>{props.statement?.groupName}</Typography>
                                <Typography>
                                    <strong>Преподаватели: </strong>{props.statement?.employees?.join(', ')}
                                </Typography>
                            </Stack>
                        </Item>
                    </Grid>
                    <Grid item md={6}>
                        <Item>
                            <Stack spacing={3}>
                                <Typography><strong>Статус: </strong>{props.statement?.closingDate !== null ? 'Ведомость закрыта' : 'Ведомость открыта'}</Typography>
                                <Typography><strong>Дата выдачи: </strong>{props.statement?.creationDate !== null && props.statement?.creationDate !== undefined ? toDateString(props.statement?.creationDate) : '-'}</Typography>
                            </Stack>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}