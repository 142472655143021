import { ArrowBack } from '@mui/icons-material';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled, alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import NavSection from '../../../components/nav-section';
import Scrollbar from '../../../components/scrollbar';
import useResponsive from '../../../hooks/useResponsive';
import {useGetUserDetailsQuery} from "../../../store/user/userApi";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import fillNav from "./config";

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const isDesktop = useResponsive('up', 'lg');
  const navigate = useNavigate();

  const {data: userData} = useGetUserDetailsQuery();

  const { pathname } = useLocation();
  const [semestersList, setSemestersList] = useState([]);
  const [navStack, setNavStack] = useState([]);
  const [selectedSemester, setSelectedSemester] = useState(localStorage.getItem("selectedSemesterId"));
  console.log(`Selected semester`, selectedSemester);

  useEffect(() => {
    setNavStack(fillNav());
    if (semestersList.length === 0 && userData !== null && userData !== undefined) {
      setSemestersList(copyObject(userData.availableSemesters))
    }
    
    if (openNav) {
      onCloseNav();
    }
  }, [pathname, userData, navigate]);

  function copyObject(obj) {
    return JSON.parse(JSON.stringify(obj));
  }
  
  function changeSemester(value) {
    if (value === null || value === undefined)
      return;
    
    setSelectedSemester(value);
    localStorage.setItem("selectedSemesterId", value);
    
    // reload
    window.location.reload();
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{mb: 5, mx: 2.5, mt: 5}}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src={userData?.imageUri ?? ""} alt="photoURL"/>
            
            <Box sx={{ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {userData?.fullName}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <Stack paddingLeft={2} paddingRight={2}>
        <Select size={"small"} 
                variant={"outlined"}
                placeholder={"Семестр"}
                onChange={(event) => changeSemester(event.target.value)}
                value={selectedSemester}>
          {semestersList?.map((item) => {
            return <MenuItem key={item.value} value={item.key}>{item.value}</MenuItem>
          })}
        </Select>
      </Stack>
      
      <NavSection data={navStack} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          onClose={onCloseNav}
          open={openNav}
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: false,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
