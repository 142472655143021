import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Box, Button,
    Card,
    CardContent, CircularProgress,
    Container, FormControlLabel,
    Grid, Input,
    Link,
    Paper,
    Stack, Switch, Table, TableBody, TableCell,
    TableHead,
    TableRow, ToggleButton, ToggleButtonGroup,
    Typography
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {PrintOutlined, Save} from "@mui/icons-material";
import Scrollbar from "../components/scrollbar";
import StatementInfoBlock from "../sections/@dashboard/sessions/StatementInfoBlock";
import {GeneralLayout} from "../layouts/GeneralLayout";
import {useParams} from "react-router-dom";
import {
    useDeleteStudentFromStatementMutation,
    useGetStatementInfoQuery,
    useGetStatementResultsQuery,
    useUpdateStatementGradeMutation
} from "../store/statement/statementApi";
import FileUploadButton from "../sections/@dashboard/common/FileUploadButton";
import MainLayout from "./main";
import PageTitle from "../components/PageTitle";

StatementResultsPage.propTypes = {
    statementId: PropTypes.number
}

export default function StatementResultsPage(props) {
    const {id} = useParams();
    const [adminMode, setAdminMode] = useState(false);
    const [adminChangesAreSaving, setAdminChangesAreSaving] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const {data} = useGetStatementInfoQuery({statementId: id})
    const results = useGetStatementResultsQuery({statementId: id});
    const [copyOfResults, setCopyOfResults] = useState([]);
    const roles = (localStorage.getItem('roles') ?? '').split(',');
    const isEmployee = hasRole('employee');
    const isAdmin = hasRole('AttNewAdmin');
    const [updateStatementGrade] = useUpdateStatementGradeMutation();
    const [removeStudentFromStatement] = useDeleteStudentFromStatementMutation();
    if (isAdmin)
        console.log("Admin mode enabled");

    useEffect(() => {
        if (results?.data !== null && results?.data !== undefined && results) {
            setCopyOfResults(copyObject(results.data));
        }
    }, [results]);

    function hasRole(roleName) {
        return roles.find((item) => item === roleName) !== undefined
    }

    function copyObject(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    function toDateString(date) {
        if (date === null)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    function applyVisualChanges() {
        setRefresh(!refresh);
    }

    function handleAdminGradeChange(student, value) {
        student.gradeId = value;
        student.gradeName = getGradeName(value)
        applyVisualChanges();
    }
    
    async function removeStudent(student) {
        if (!isAdmin)
            return;

        // НЕ УДАЛЯТЬ КОММЕНТАРИЙ НИЖЕ! ЭТО ПРАВИЛО ESLINT
        // eslint-disable-next-line no-restricted-globals
        const agree = confirm(`Вы уверены, что хотите удалить студента ${student.lastName} ${student.firstName} из ведомости?`);
        if (!agree) {
            return;
        }

        setAdminChangesAreSaving(true);

        await removeStudentFromStatement({
            data: {
                statementId: id,
                studentId: student.studentId
            }
        }).unwrap()
            .then((fulfilled) => {
                if (fulfilled?.success) {
                    setAdminChangesAreSaving(false);
                    student.deleted = true;
                    applyVisualChanges();
                } else {
                    fulfilled.errors.map((item) => {
                        alert(item);
                        return 1;
                    });

                    setAdminChangesAreSaving(false);
                }
            }).catch((reason) => {
                alert("Ошибка");
                setAdminChangesAreSaving(false);
            });
    }

    async function adminSaveGradeChanges(student) {
        setAdminChangesAreSaving(true);

        await updateStatementGrade({
            data: {
                statementId: id,
                studentId: student.studentId,
                gradeId: student.gradeId
            }
        }).unwrap()
            .then((fulfilled) => {
                if (fulfilled?.success) {
                    setAdminChangesAreSaving(false);
                } else {
                    fulfilled.errors.map((item) => {
                        alert(item);
                        return 1;
                    });

                    setAdminChangesAreSaving(false);
                }
            }).catch((reason) => {
                alert("Ошибка");
                setAdminChangesAreSaving(false);
            });
    }

    function getGradeName(gradeId) {
        switch (gradeId) {
            case 1:
                return 'Не выставлено';
            case 2:
                return 'Неудовлетворительно';
            case 3:
                return 'Удовлетворительно';
            case 4:
                return 'Хорошо';
            case 5:
                return 'Отлично';
            case 6:
                return 'Не допущен';
            case 7:
                return 'Неявка';
            default:
                return 'Неизвестный тип оценки';
        }
    }

    const content = (<>
        <PageTitle title={`Результаты ведомости`}/>

        <Container>
            <Stack
                padding={isEmployee ? "30px 0px 0px 0px" : "100px 0px 0px 100px"}
                direction="column"
                spacing={2}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Результаты ведомости №{data?.statement.statementNumber}
                    </Typography>
                    {isAdmin
                        ? <FormControlLabel control={
                            <Switch inputProps={{'aria-label': 'controlled'}}
                                    checked={adminMode}
                                    onChange={(e, check) => setAdminMode(check)}/>
                        } label="Админ"/>
                        : <></>}
                </Stack>

                <StatementInfoBlock statement={data?.statement} 
                                    printEnabled={data?.statement?.closingDate !== null}
                                    adminMode={adminMode}/>

                <br/>

                <Card>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>ФИО студента</TableCell>
                                <TableCell>Оценка</TableCell>
                                <TableCell>Допуск</TableCell>
                                {data?.statement?.controlType === 3 || data?.statement?.controlType === 4
                                    ? <TableCell align={"right"}>Рецензия/Работа</TableCell>
                                    : <></>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {copyOfResults.students?.sort((left, right) => left.lastName > right.lastName ? 1 : -1).map((student, index) => {
                                return (
                                    <TableRow>
                                        <TableCell><Typography
                                            fontSize="x-large">{index + 1}</Typography></TableCell>
                                        <TableCell>
                                            <Typography>
                                                <strong>{student.lastName}</strong>
                                                <br/>{student.firstName} {student.middleName}
                                            </Typography>
                                            {adminMode
                                            ? <Button variant={"outlined"} 
                                                      color={"error"}
                                                      disabled={adminChangesAreSaving || student.deleted}
                                                      onClick={() => removeStudent(student)}>Удалить</Button>
                                            : <></>}
                                            {student.deleted
                                            ? <Typography color={'error'}>Удален</Typography>
                                            : <></>}
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                color={student.gradeId === 2 || student.gradeId === 7 || student.gradeId === 6
                                                    ? 'red'
                                                    : student.gradeId === 3
                                                        ? 'orange'
                                                        : 'green'}>
                                                {student.gradeName}
                                            </Typography>
                                            {adminMode
                                                ? <>
                                                    <ToggleButtonGroup orientation="horizontal"
                                                                       exclusive
                                                                       fullWidth
                                                                       size={"small"}
                                                                       value={student.gradeId}
                                                                       aria-label="Оценка"
                                                                       color="primary"
                                                                       onChange={(event, value) => handleAdminGradeChange(student, value)}>
                                                        <ToggleButton value={5} area-label="Отлично"
                                                                      color="success">{data?.controlType !== 1 ? '5' : 'Зачтено'}</ToggleButton>
                                                        {data?.controlType !== 1
                                                            ? <ToggleButton value={4} area-label="Хорошо"
                                                                            color="success">4</ToggleButton>
                                                            : <></>}
                                                        {data?.controlType !== 1
                                                            ? <ToggleButton value={3} area-label="Удовлетворительно"
                                                                            color="warning">3</ToggleButton>
                                                            : <></>}
                                                        <ToggleButton value={2} area-label="Неудовлетворительно"
                                                                      color="error">{data?.controlType !== 1 ? '2' : 'Не зачтено'}</ToggleButton>
                                                        <ToggleButton value={7} area-label="н/я"
                                                                      color="error">н/я</ToggleButton>
                                                        <ToggleButton value={1} area-label="-"
                                                                      color="info">-</ToggleButton>
                                                    </ToggleButtonGroup>

                                                    <Button
                                                        startIcon={adminChangesAreSaving ? <CircularProgress size={15}/> :
                                                            <Save/>}
                                                        size={'small'}
                                                        disabled={adminChangesAreSaving || student.deleted}
                                                        onClick={() => adminSaveGradeChanges(student)}>Сохранить
                                                        изменения</Button>
                                                </>
                                                : <></>}
                                            {student.registryDocumentGuid !== null
                                                ?
                                                <Typography fontSize={"small"}>Перезачтено на основании документа <Link
                                                    href={`https://fs.kubsau.ru/File/${student.registryDocumentGuid}`}
                                                    target={'_blank'}>{student.registryDocumentNumber} от {toDateString(student.registryDocumentDate)}</Link></Typography>
                                                : <></>}
                                        </TableCell>
                                        <TableCell>
                                            <Typography color={student.gradeId !== 6 ? 'green' : 'red'}>
                                                {student.gradeId !== 6 ? 'Допущен' : 'Не допущен'}
                                            </Typography>
                                        </TableCell>
                                        {data?.statement?.controlType === 3 || data?.statement?.controlType === 4
                                            ? <TableCell align={"right"}>
                                                {student.theme !== null && student.theme !== undefined
                                                    ? <><strong>Тема работы: </strong>{student.theme}</>
                                                    : <></>}

                                                {student.reviewFile !== null && student.reviewFile !== undefined
                                                    ? <FileUploadButton maxFileSize={0} title={'Рецензия'}
                                                                        uploadingEnabled={0}
                                                                        existingFileGuid={student.reviewFile}/>
                                                    : <></>}

                                                {student.courseWorkFile !== null && student.courseWorkFile !== undefined
                                                    ? <FileUploadButton maxFileSize={0} title={'Работа'}
                                                                        uploadingEnabled={0}
                                                                        existingFileGuid={student.courseWorkFile}/>
                                                    : <></>}
                                            </TableCell>
                                            : <></>}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </Card>

                <Box height={100}/>
            </Stack>
        </Container>
    </>)

    return (
        isEmployee
            ? <MainLayout>
                {content}
            </MainLayout>
            : <GeneralLayout>
                {content}
            </GeneralLayout>
    )
}