import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer,
    CardContent,
    Button,
    CircularProgress,
    Box,
    TableHead,
    TableRow,
    TableCell,
    Table,
    TableBody,
    Checkbox,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import {Alert} from "@mui/lab";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useCreateSessionStopRequestMutation, useSessionGeneralInfoQuery} from "../store/session/sessionApi";
import PageTitle from "../components/PageTitle";

export default function CreateSessionStopRequestPage() {
    const {sessionId} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const [description, setDescription] = useState('');
    const [umuRelatedChanges, setUmuRelatedChanges] = useState(false);
    const [dispatcherRelatedChanges, setDispatcherRelatedChanges] = useState(false);
    const [iUnderstand, setIUnderstand] = useState(false);
    const [createSessionStopRequest] = useCreateSessionStopRequestMutation();
    const {data} = useSessionGeneralInfoQuery({sessionId}); 


    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const handleSave = async () => {
        setIsSaving(true);

        const request = {
            sessionId,
            umuRelatedChanges,
            dispatcherRelatedChanges,
            description
        };
        
        await createSessionStopRequest({data: request}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                navigate('/sessions');
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });

                setIsSaving(false);
            }
        });
    };

    return (
        <GeneralLayout>
            <PageTitle title={`Оформление заявки на прерывание сессии`} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress /> : <></>}
                            Оформление заявки на прерывание сессии для {data?.courseName}
                        </Typography>
                    </Stack>
                    
                    <Alert variant={"outlined"} color={"warning"}>
                        <Typography>
                            Вы пытаетесь создать заявку к Учебно-методическому управлению с просьбой приостановить ход сессии курса для {data?.courseName}. После создания, заявка будет рассмотрена Учебно-методическим управлением и, в случае положительного ответа, с сессии будет снято утверждение и вы получите возможность редактировать сессию. Имейте в виду, что нельзя редактировать расписание для пар группа-предмет у которых уже есть созданные ведомости.
                        </Typography>
                        <Typography color={"brown"}>
                            После снятия утверждения Учебно-методическим управлением, преподаватели не смогут заполнять ведомости по предметам, приведённым в таблице, до тех пор, пока сессия не будет заново утверждена.
                        </Typography>
                    </Alert>

                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Typography variant={"h5"}>Список затрагиваемых ведомостей</Typography>
                                
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Дисциплина</TableCell>
                                            <TableCell align={"right"}>Список ведомостей</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={2} align={"center"}>
                                                <strong>Экзамены</strong>
                                            </TableCell>
                                        </TableRow>
                                        {data?.disciplines.filter((item) => item.controlType === 0).length === 0
                                            ? <TableRow><TableCell colSpan={2} align={"center"}>Нет записей</TableCell></TableRow>
                                            : <></>}
                                        {data?.disciplines.filter((item) => item.controlType === 0)
                                            .map((discipline) => {
                                            return <TableRow>
                                                <TableCell>{discipline.disciplineName}</TableCell>
                                                <TableCell align={"right"}>
                                                    {discipline.statements.length === 0
                                                    ? 'Нет ведомостей'
                                                    : ''}
                                                    {discipline.statements.map((statement) => {
                                                        return <><Button href={`/statementresults/${statement.statementId}`} target={'_blank'}>Ведомость №{statement.statementNumber}</Button> ({statement.closed? 'Закрытая' : 'Открытая'}) <br /></>
                                                    })}
                                                </TableCell>
                                            </TableRow>
                                        })}

                                        <TableRow>
                                            <TableCell colSpan={2} align={"center"}>
                                                <strong>Зачёты</strong>
                                            </TableCell>
                                        </TableRow>
                                        {data?.disciplines.filter((item) => item.controlType === 1 || item.controlType === 2).length === 0
                                        ? <TableRow><TableCell colSpan={2} align={"center"}>Нет записей</TableCell></TableRow>
                                        : <></>}
                                        {data?.disciplines.filter((item) => item.controlType === 1 || item.controlType === 2)
                                            .map((discipline) => {
                                                return <TableRow>
                                                    <TableCell>{discipline.disciplineName} {discipline.controlType === 2 ? '(Дифф.)' : ''}</TableCell>
                                                    <TableCell align={"right"}>
                                                        {discipline.statements.length === 0
                                                            ? 'Нет ведомостей'
                                                            : ''}
                                                        {discipline.statements.map((statement) => {
                                                            return <><Button  href={`/statementresults/${statement.statementId}`} target={'_blank'}>Ведомость №{statement.statementNumber}</Button> ({statement.closed? 'Закрытая' : 'Открытая'}) <br /></>
                                                        })}
                                                    </TableCell>
                                                </TableRow>
                                            })}

                                        <TableRow>
                                            <TableCell colSpan={2} align={"center"}>
                                                <strong>Курсовые работы</strong>
                                            </TableCell>
                                        </TableRow>
                                        {data?.disciplines.filter((item) => item.controlType === 3 || item.controlType === 4).length === 0
                                            ? <TableRow><TableCell colSpan={2} align={"center"}>Нет записей</TableCell></TableRow>
                                            : <></>}
                                        {data?.disciplines.filter((item) => item.controlType === 3 || item.controlType === 4)
                                            .map((discipline) => {
                                                return <TableRow>
                                                    <TableCell>{discipline.disciplineName}</TableCell>
                                                    <TableCell align={"right"}>
                                                        {discipline.statements.length === 0
                                                            ? 'Нет ведомостей'
                                                            : ''}
                                                        {discipline.statements.map((statement) => {
                                                            return <><Button  href={`/statementresults/${statement.statementId}`} target={'_blank'}>Ведомость №{statement.statementNumber}</Button> ({statement.closed? 'Закрытая' : 'Открытая'}) <br /></>
                                                        })}
                                                    </TableCell>
                                                </TableRow>
                                            })}
                                    </TableBody>
                                </Table>
                                
                                <FormControlLabel control={<Checkbox value={umuRelatedChanges} onChange={(event, checked) => setUmuRelatedChanges(checked)}/>} label={`Изменения в дате, преподавателях, типе контроля`} />
                                <FormControlLabel control={<Checkbox value={dispatcherRelatedChanges} onChange={(event, checked) => setDispatcherRelatedChanges(checked)}/>} label={`Изменения во времени или месте проведения`} />
                                
                                <Typography>Описание причины</Typography>
                                <TextField multiline 
                                           maxRows={3} 
                                           minRows={3}
                                           value={description}
                                           onInput={(event) => setDescription(event.target.value)}/>

                                <FormControlLabel control={<Checkbox value={iUnderstand} onChange={(event, checked) => setIUnderstand(checked)}/>} label={`Я понимаю последствия этого действия`} />

                                <Stack justifyContent="space-between">
                                    {
                                        !isSaving
                                            ? <Button variant="contained"
                                                      disabled={data === null || data === undefined || !iUnderstand || (!umuRelatedChanges && !dispatcherRelatedChanges)}
                                                      onClick={handleSave}>Оформить заявку</Button>
                                            : <CircularProgress/>
                                    }
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                    <br />
                </Stack>
            </Container>
        </GeneralLayout>
    );
}