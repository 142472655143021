import {useState} from "react"
import {
    TableRow,
    TableCell,
    Menu,
    MenuItem,
    IconButton,
    Collapse,
    Grid, Typography, Link, Button
} from "@mui/material"
import {ExpandMore, MoreVertRounded} from "@mui/icons-material"
import progressControl from "../../../../components/progressControl"
import DetailsBlock from "../detailsBlock"
import "./styles.scss"

/**
 * Сворачиваемая строка таблицы
 * @param row Вхождение курса
 * @param index Индекс курса
 * @param toggler Функция, отрабатывающая переключение видимости строки
 * @returns React.Node
 */
function CollapsibleRow(props) {
    const {row} = props

    const [anchorEl, setAnchorEl] = useState(null)
    const menuOpen = Boolean(anchorEl)

    // Отображение меню курса
    const handleMenuOpen = e => {
        setAnchorEl(e.currentTarget)
    }

    // Скрытие меню курса
    const handleMenuClose = () => {
        setAnchorEl(null)
    }
    
    function calculateHours(flowType) {
        let totalHours = 0;
        let filledHours = 0;
        row.flows.filter(item => item.flowType === flowType && (flowType !== 4 || item.flowType === 4))
            .forEach(item => {
                totalHours += item.totalHours;
                filledHours += item.filledHours;
            });
        
        return {
            totalHours,
            filledHours
        }
    }

    // Переключение видимости строки
    const toggleRow = e => {
        const path = Array.from(e.nativeEvent.composedPath())
        const links = path.filter(el => {
            const element = el
            return (
                element.tagName === "A" ||
                element.tagName === "BUTTON" ||
                element.id === "more-menu"
            )
        })
        if (links.length === 0) {
            // Callback
            props.toggler(!props?.isOpen ?? false, row.courseId, row.disciplineId)
        }
    }

    // let lectureTotalHours = 0;
    // let lectureFilledHours = 0;
    // row.flows.filter(item => item.flowType === 6)
    //     .forEach(item => {
    //         lectureTotalHours += item.totalHours;
    //         lectureFilledHours += item.filledHours;
    //     });
    
    const lectureTotals = calculateHours(6);
    const laboratoryTotals = calculateHours(1);
    const seminarTotals = calculateHours(3);


    const lectionControl = progressControl(
        lectureTotals.filledHours,
        lectureTotals.totalHours
    ) // Лекции в дочерней таблице

    const seminarControl = progressControl(
        seminarTotals.filledHours,
        seminarTotals.totalHours
    ) // Семинары в дочерней таблице

    const labControl = progressControl(
        laboratoryTotals.filledHours,
        laboratoryTotals.totalHours
    ) //  Лабораторные работы в дочерней таблице

    // Положение стрелочки
    const transform = props?.isOpen ?? false ? "rotate(180deg)" : "rotate(0deg)"

    return (
        <>
            <TableRow hover onClick={toggleRow} sx={{cursor: "pointer"}}>
                <TableCell sx={{borderBottom: 0, fontSize: "clamp(16px, 3vw, 18px)"}}>
                    <ExpandMore
                        sx={{transition: "transform .4s", transform}}
                    />
                </TableCell>
                <TableCell sx={{borderBottom: 0, fontSize: "clamp(16px, 3vw, 18px)"}}>
                    {row.disciplineName}
                    <br/>
                    <Typography fontSize={'small'}>{row.courseName}</Typography>
                    {!row.hasVirtualGroups && row.flows.filter((flow) => !flow.studentsSetBySubgroups && flow.flowType === 1).length > 0
                        ? <><Typography fontSize={'small'} color={'red'}>Не все студенты распределены по подгруппам</Typography></>
                    : <></>}
                    {row.hasVirtualGroups
                    ? <Typography fontSize={'small'} color={'orange'}>Имеются сводные группы</Typography>
                    : <></>}
                </TableCell>
                <TableCell sx={{borderBottom: 0, fontSize: "clamp(16px, 3vw, 18px)"}}>
                    {lectionControl}
                </TableCell>
                <TableCell sx={{borderBottom: 0, fontSize: "clamp(16px, 3vw, 18px)"}}>
                    {seminarControl}
                </TableCell>
                <TableCell sx={{borderBottom: 0, fontSize: "clamp(16px, 3vw, 18px)"}}>
                    {labControl}
                </TableCell>
                <TableCell
                    sx={{
                        borderBottom: 0,
                        fontSize: "clamp(16px, 3vw, 18px)",
                        textAlign: "right"
                    }}
                >
                    <IconButton
                        aria-label="Действия"
                        aria-controls={menuOpen ? "more-menu" : undefined}
                        aria-haspopup
                        aria-expanded={menuOpen ? "true" : undefined}
                        onClick={handleMenuOpen}
                    >
                        <MoreVertRounded/>
                    </IconButton>
                    <Menu
                        id="more-menu"
                        anchorEl={anchorEl}
                        open={menuOpen}
                        onClose={handleMenuClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right"
                        }}
                        transformOrigin={{
                            horizontal: "right",
                            vertical: "top"
                        }}
                    >
                        {/* <MenuItem>Список тем</MenuItem> */}
                        <MenuItem>
                            <Link href={`/disciplinegroupjournal/${row.courseId}/${row.disciplineId}`} 
                                  color={'inherit'}
                                  underline={'none'}>Журнал</Link>
                        </MenuItem>
                        {row.hasVirtualGroups ? <></> : <MenuItem><Link href={`/editsubgroups/${row.courseId}/${row.disciplineId}`}
                                                                        color={'inherit'}
                                                                        underline={'none'}>Подгруппы</Link></MenuItem>}
                        {/* <MenuItem>Пропуски студентов</MenuItem> */}
                    </Menu>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={6} sx={{padding: 0}}>
                    <Collapse in={props?.isOpen ?? false} timeout="auto" unmountOnExit>
                        <div className="details">
                            <Grid container spacing={4}>
                                <DetailsBlock
                                    view={"CARD"}
                                    data={row.flows.filter((item) => item.flowType === 6)}
                                    title="Лекции"
                                />
                                <DetailsBlock
                                    view={"TABLE"}
                                    data={row.flows.filter((item) => item.flowType === 3 || item.flowType === 4)}
                                    title="Семинары"
                                />
                                <DetailsBlock
                                    view={"TABLE"}
                                    data={row.flows.filter((item) => item.flowType === 1)}
                                    title="Лабораторные работы"
                                />
                            </Grid>
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default CollapsibleRow
