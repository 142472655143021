import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer, CardContent, Button, CircularProgress, Box, TableCell, TableRow, TableHead, Table, TableBody,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import AddIcon from "@mui/icons-material/Add";
import {InfoSharp} from "@mui/icons-material";
import {useGetOutsidePlanPassingsQuery} from "../store/session/sessionApi";
import PageTitle from "../components/PageTitle";

export default function OutsidePlanPassingListPage() {
    const {sessionId} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const {data} = useGetOutsidePlanPassingsQuery({sessionId});

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }
    
    function getApproveStatusName(approveStatus) {
        switch (approveStatus) {
            case 0: return 'Не отправлено на утверждение в УМУ';
            case 1: return 'Отправлено на утверждение в УМУ';
            case 2: return 'Отправлено на утверждение в диспетчерскую';
            case 3: return 'Возвращено для внесения изменений по решению диспетчерской';
            case 4: return 'Запрос на прерывание отправлен в УМУ';
            case 5: return 'Запрос на прерывание отправлен в диспетчерскую';
            case 6: return 'Запрос на прерывание отправлен в УМУ и диспетчерскую';
            case 7: return 'Возвращено для внесения изменений по решению УМУ';
            case 100: return 'Утверждено';
            default: return 'Неизвестный статус';
        }
    }

    const handleSave = async () => {
        setIsSaving(true);

        // await createAttestation({courseId: id, startDate, endDate}).unwrap().then((fulfilled) => {
        //     if (fulfilled?.success) {
        //         navigate('/facultyattestations');
        //     } else {
        //         fulfilled.errors.map((item) => {
        //             alert(item);
        //             return 1;
        //         });
        //
        //         setIsSaving(false);
        //     }
        // });
    };

    return (
        <GeneralLayout>
            <PageTitle title={`Управление внеплановыми сдачами`} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress /> : <></>}
                            Управление внеплановыми сдачами сессии для {data?.data?.courseName}
                        </Typography>
                    </Stack>
                    <Stack alignItems={"start"}>
                        <Button startIcon={<AddIcon />}
                                size={"small"}
                                variant={"contained"}
                                href={`/create-outside-plan-passing/${sessionId}`}>Создать внеплановую сдачу</Button>
                    </Stack>

                    <Card>
                        {data?.data?.outsidePlanPassings?.length === 0
                        ? <Typography align={"center"}>Нет записей</Typography>
                        : <Table size={"small"}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Группа</TableCell>
                                        <TableCell>Студенты</TableCell>
                                        <TableCell>Номер распоряжения</TableCell>
                                        <TableCell>Ход сдачи</TableCell>
                                        <TableCell>Операции</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data?.data?.outsidePlanPassings.map((item) => {
                                        return <TableRow>
                                            <TableCell>{item.groupName}</TableCell>
                                            <TableCell>
                                                {item.students.map((s) => {
                                                    return <Typography>{s.value}</Typography>
                                                })}
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{item.orderNumber}</Typography>
                                                {item.individualSchedule ? <Typography>Индивидуальный график</Typography> : <></>}
                                                {item.badGradesRetake ? <Typography>Пересдача "3" и "4"</Typography> : <></>}
                                            </TableCell>
                                            <TableCell>
                                                {getApproveStatusName(item.approveStatus)}
                                            </TableCell>
                                            <TableCell>
                                                <Button startIcon={<InfoSharp />}
                                                        href={`/outside-plan-passing-details/${item.outsidePlanPassingId}`}>Подробности</Button>
                                            </TableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>}
                        
                    </Card>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}