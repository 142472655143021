import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer, CardContent, Button, CircularProgress, Box, CardActions, Table, TableBody, TableCell, TableRow,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import React, {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import {AccountBalance, Article, DocumentScanner, House, Info, Newspaper, Star} from "@mui/icons-material";
import {ArrowRightIcon} from "@mui/x-date-pickers";
import UmuCard from "../sections/@dashboard/common/UmuCard";
import {useGetUmuSummaryQuery} from "../store/umu/umuApi";
import PageTitle from "../components/PageTitle";

export default function UmuMainPage() {
    const navigate = useNavigate();
    const {data} = useGetUmuSummaryQuery();
    const roles = localStorage.getItem("roles").split(',');
    const isUmu = isRole('AttNewUmu');
    const isDispatcher = isRole('AttNewDispatcher');

    function isRole(name) {
        return roles.find((item) => item === name) !== undefined;
    }

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    return (
        <GeneralLayout>
            <PageTitle title={`Управление`}/>

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress /> : <></>}
                            {isUmu ? 'Учебно-методическое управление' : 'Диспетчерская'}
                        </Typography>
                    </Stack>

                    <Card>
                        <CardContent>
                            <Stack direction={"row"} fullWidth spacing={3}>
                                <UmuCard color={"#337ab7"}
                                         title={"Заявки на утверждение сессий"}
                                         icon={<Article fontSize={"large"}/>}
                                         url={"/session-approve-list"}
                                         number={data?.data?.numberOfRequestsForSessionApproval ?? 0}/>

                                {isUmu
                                ? <UmuCard color={"#d35401"}
                                           title={"Заявки на утверждение практик"}
                                           icon={<AccountBalance fontSize={"large"}/>}
                                           url={"/practice-approve-list"}
                                           number={data?.data?.numberOfRequestsForPracticeApproval ?? 0}/>
                                : <></>}

                                <UmuCard color={"#16a085"}
                                         title={"Заявки на досрочные сессии"}
                                         icon={<Star fontSize={"large"}/>}
                                         url={"/outside-plan-passings-approval-list"}
                                         number={data?.data?.numberOfRequestsForOutsidePlanSessionApproval ?? 0}/>

                                <UmuCard color={"#f0ad4e"}
                                         title={"Запросов на прерывание сессии"}
                                         icon={<Info fontSize={"large"}/>}
                                         url={"/session-stop-request-list"}
                                         number={data?.data?.numberOfRequestsForSessionSuspending ?? 0}/>
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}