import {
    Button, Card,
    CardActions,
    CardContent,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import {ArrowRightIcon} from "@mui/x-date-pickers";
import PropTypes from "prop-types";

UmuCard.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    number: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
}

export default function UmuCard(props) {
    return <Card variant={"outlined"} style={{"backgroundColor": props.color}}>
        <CardContent>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Typography color={"white"}>
                                {props.icon}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Stack width={100} alignItems={"end"} textAlign={"right"}>
                                <Typography fontSize={"xxx-large"} color={"white"}>
                                    {props.number}
                                </Typography>
                                <Typography color={"white"}>
                                    {props.title}
                                </Typography>
                            </Stack>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </CardContent>
        <CardActions style={{"backgroundColor": "white"}}>
            <Button endIcon={<ArrowRightIcon />} fullWidth href={props.url}>Перейти к списку</Button>
        </CardActions>
    </Card>
}