import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer, CardContent, Button, CircularProgress, Box, Table, TableRow, TableCell, TableHead,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import React, {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import {ArrowRightIcon} from "@mui/x-date-pickers";
import PageTitle from "../components/PageTitle";

export default function DocumentsPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const data = null;


    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const handleSave = async () => {
        setIsSaving(true);

        // await createAttestation({courseId: id, startDate, endDate}).unwrap().then((fulfilled) => {
        //     if (fulfilled?.success) {
        //         navigate('/facultyattestations');
        //     } else {
        //         fulfilled.errors.map((item) => {
        //             alert(item);
        //             return 1;
        //         });
        //
        //         setIsSaving(false);
        //     }
        // });
    };

    return (
        <GeneralLayout>
            <PageTitle title={`Документы`} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Документы
                        </Typography>
                    </Stack>

                    <Card>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Раздел</TableCell>
                                    <TableCell align={"right"}><></></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableRow>
                                <TableCell>
                                    <Button startIcon={<ArrowRightIcon />}
                                            href={'/certificates-of-skipping'}>Справки о пропусках</Button>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Button startIcon={<ArrowRightIcon />}
                                            href={`/registry-of-documents`}>Реестр документов</Button>
                                </TableCell>
                            </TableRow>
                        </Table>
                    </Card>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}