import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer, CardContent, Button, CircularProgress, Box, TableHead, TableRow, TableCell, Table, TableBody,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import {Check, InfoSharp} from "@mui/icons-material";
import {useGetOutsidePlanPassingListForApprovalQuery} from "../store/session/sessionApi";
import PageTitle from "../components/PageTitle";

export default function OutsidePlanPassingsApprovalListPage() {
    const navigate = useNavigate();
    const roles = localStorage.getItem('roles').split(',');
    const isUmu = roles.find((item) => item === 'AttNewUmu') !== undefined;
    const isDispatcher = roles.find((item) => item === 'AttNewDispatcher') !== undefined;
    const {data} = useGetOutsidePlanPassingListForApprovalQuery();
    
    function approvalStatusColor(status) {
        switch (status) {
            case 1: return '#fcf8e3';
            case 2: return '#fcf8e3';
            case 100: return '#dff0d8';
            default: return '';
        }
    }

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    return (
        <GeneralLayout>
            <PageTitle title={`Управление внеплановыми сдачами`} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress /> : <></>}
                            Управление внеплановыми сдачами
                        </Typography>
                    </Stack>

                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                {data?.data?.map((item) => {
                                    return <>
                                        <Typography variant={"h6"}>Факультет {item.facultyName}</Typography>

                                        <Table size={'small'}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Курс</TableCell>
                                                    <TableCell>Студенты</TableCell>
                                                    <TableCell>Номер приказа</TableCell>
                                                    <TableCell>Операции</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {item.passings.map((pass) => {
                                                    return <TableRow style={{'backgroundColor': approvalStatusColor(pass.approvalStatus)}}>
                                                        <TableCell>{pass.courseName}</TableCell>
                                                        <TableCell>
                                                            {pass.students.map((s) => {
                                                                return <Typography fontSize={"small"}>{s.value}</Typography>
                                                            })}
                                                        </TableCell>
                                                        <TableCell>{pass.orderNumber}</TableCell>
                                                        <TableCell>
                                                            {isUmu && pass.approvalStatus === 1 || pass.approvalStatus === 2 && isDispatcher
                                                            ? <><><Button startIcon={<Check color={"success"} />} 
                                                                          href={`/approve-outside-plan-passing-request/${pass.outsidePlanPassingId}`}>Подтвердить</Button></></>
                                                            : (pass.approvalStatus >= 100
                                                                ? <><Button startIcon={<InfoSharp />}>Просмотр информации</Button></>
                                                                : <></>)}
                                                        </TableCell>
                                                    </TableRow>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </>
                                })}
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}