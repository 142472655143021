import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
import { alpha } from '@mui/material/styles';
import {useEffect, useState} from 'react';
// @mui

import {useGetUserDetailsQuery, useTestQuery} from "../../../store/user/userApi";
import {useNavigate} from "react-router-dom";


export default function AccountPopover() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleSignOut = () => {
    localStorage.setItem("token", null);
    localStorage.setItem("roles", null);
    
    window.location.replace('https://auth.kubsau.ru');

    setOpen(null);
  }

  const {data: userData} = useGetUserDetailsQuery();

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={userData?.imageUri} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >

        

        <MenuItem onClick={handleSignOut} sx={{ m: 1 }}>
          Выйти
        </MenuItem>
      </Popover>
    </>
  );
}
