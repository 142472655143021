import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {prepareAuthHeaders} from "../../utils/prepareAuthHeaders";

export const employeeApi = createApi({
    reducerPath: 'employeeApi',
    keepUnusedDataFor: 1,
    baseQuery: fetchBaseQuery({
        baseUrl: '/Employee',
        prepareHeaders: prepareAuthHeaders,
    }),
    endpoints: (builder) => ({
        listEmployees: builder.query({
            query: () => ({
                url: `/ListEmployees`,
                method: 'GET'
            }),
        }),
        getLaboratoryWorkDisciplinesOfEmployee: builder.query({
            query: () => ({
                url: `/LaboratoryWorkDisciplinesOfEmployee`,
                method: 'GET'
            }),
        }),
        getGroupsOfEmployeeDiscipline: builder.query({
            query: ({disciplineId}) => ({
                url: `/GroupsOfEmployeeDiscipline`,
                method: 'GET',
                params: {disciplineId}
            }),
        }),
    }),
});

export const {
    useListEmployeesQuery,
    useGetLaboratoryWorkDisciplinesOfEmployeeQuery,
    useGetGroupsOfEmployeeDisciplineQuery
} = employeeApi;
