import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer,
    CardContent,
    Button,
    CircularProgress,
    Box,
    Divider,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody, ButtonGroup,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import {FileOpen, Print} from "@mui/icons-material";
import {useGetOutsidePlanPassingDetailsQuery} from "../store/session/sessionApi";
import PageTitle from "../components/PageTitle";

export default function OutsidePlanPassingDetailsPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const {data} = useGetOutsidePlanPassingDetailsQuery({id});
    
    function getStatementsTable(controlType) {
        const statements = data?.data?.statements.filter((item) =>
            controlType === 1
                ? item.controlType === 1 || item.controlType === 2
                : controlType === 3
                    ? item.controlType === 3 || item.controlType === 4
                    : item.controlType === 0
        );
        
        console.log(`Для типа контроля ${controlType} найдены:`, statements)
        
        return statements?.length > 0
        ? <><Table size={'small'}>
                <TableHead>
                    <TableRow>
                        <TableCell>Дисциплина</TableCell>
                        <TableCell>ФИО преподавателя</TableCell>
                        <TableCell>Номер ведомости</TableCell>
                        <TableCell>Место и время проведения</TableCell>
                        <TableCell>Дата закрытия</TableCell>
                        <TableCell>Действия</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {statements?.map((item) => {
                        return <TableRow>
                            <TableCell>{item.disciplineName} {item.controlType === 2 ? '(Дифф.)' : ''}</TableCell>
                            <TableCell>{item.employees.join(', ')}</TableCell>
                            <TableCell>{item.statementNumber}</TableCell>
                            <TableCell>{item.eventPlace}<br />{toDateString(item.eventDate)}</TableCell>
                            <TableCell>{item.closingDate === null ? 'Не закрыта' : toDateString(item.closingDate)}</TableCell>
                            <TableCell>
                                <Button startIcon={<FileOpen />}
                                        href={`/statementresults/${item.statementId}`}
                                        target={'_blank'}>Подробности</Button>
                                <br />
                                <Button startIcon={<Print />}>Справочная ведомость</Button>
                            </TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table></>
            : <Typography>Нет записей</Typography>
    }

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    function toDateString(date) {
        if (date === null)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    function getApproveStatusName(approveStatus) {
        switch (approveStatus) {
            case 0: return 'Не отправлено на утверждение в УМУ';
            case 1: return 'Отправлено на утверждение в УМУ';
            case 2: return 'Отправлено на утверждение в диспетчерскую';
            case 3: return 'Возвращено для внесения изменений по решению диспетчерской';
            case 4: return 'Запрос на прерывание отправлен в УМУ';
            case 5: return 'Запрос на прерывание отправлен в диспетчерскую';
            case 6: return 'Запрос на прерывание отправлен в УМУ и диспетчерскую';
            case 7: return 'Возвращено для внесения изменений по решению УМУ';
            case 100: return 'Утверждено';
            default: return 'Неизвестный статус';
        }
    }

    return (
        <GeneralLayout>
            <PageTitle title={`Детали внеплановой сдачи сессии`} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress /> : <></>}
                            Детали внеплановой сдачи сессии
                        </Typography>
                    </Stack>

                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Typography variant={'h5'}>Информация о сдаче</Typography>
                                <Stack spacing={0}>
                                    <Typography><strong>Факультет: </strong>{data?.data?.facultyName}</Typography>
                                    <Typography><strong>Курс: </strong>{data?.data?.courseName}</Typography>
                                    <Typography><strong>Номер распоряжения: </strong>{data?.data?.orderNumber}</Typography>
                                    <Typography><strong>Статус утверждения: </strong>{getApproveStatusName(data?.data?.approveStatus)}</Typography>
                                </Stack>

                                <Divider/>

                                <Typography variant={'h5'}>Общий cписок студентов (участвующих в этой сдаче)</Typography>
                                <ol>
                                    {data?.data?.students.map((item) => {
                                        return <li>{item.value}</li>
                                    })}
                                </ol>

                                <Divider/>

                                <Typography variant={'h5'}>Список ведомостей (которые относятся к этой сдаче)</Typography>

                                <Typography variant={'h6'}>Экзамены</Typography>
                                {getStatementsTable(0)}

                                <Typography variant={'h6'}>Зачёты</Typography>
                                {getStatementsTable(1)}

                                <Typography variant={'h6'}>Курсовые работы/проекты</Typography>
                                {getStatementsTable(3)}
                                
                                <Divider />
                                
                                <Typography variant={'h6'}>История замечаний</Typography>
                                <Table size={'small'}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Детали замечания</TableCell>
                                            <TableCell>Дата</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data?.data?.reviews.map((item) => {
                                            return <TableRow>
                                                <TableCell>{item.text}</TableCell>
                                                <TableCell>{item.date}</TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}