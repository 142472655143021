import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer,
    CardContent,
    Button,
    CircularProgress,
    Box,
    Checkbox,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody, Backdrop,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useGetFacultiesQuery} from "../store/faculty/facultyApi";
import {
    useListFacultySessionsByIdQuery
} from "../store/session/sessionApi";
import CheckIcon from "@mui/icons-material/Check";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import StarIcon from "@mui/icons-material/Star";
import ArticleIcon from "@mui/icons-material/Article";
import PrintIcon from "@mui/icons-material/Print";
import {Alert} from "@mui/lab";
import PageTitle from "../components/PageTitle";

export default function SessionApproveListPage() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [fileIsLoading, setFileIsLoading] = useState(false);
    const [selectedFacultyId, setSelectedFacultyId] = useState(null);
    const {data: faculties} = useGetFacultiesQuery();
    const sessionsOfFacultyQuery = useListFacultySessionsByIdQuery({facultyId: selectedFacultyId});
    const [facultiesData, setFacultiesData] = useState([]);
    const [onlyApproval, setOnlyApproval] = useState(false);
    const roles = localStorage.getItem('roles')?.split(',') ?? [];
    const isUmu = roles.find((item) => item === 'AttNewUmu') !== undefined;
    const isDispatcher = roles.find((item) => item === 'AttNewDispatcher') !== undefined;
    
    useEffect(() => {
        console.log('faculty has selected', selectedFacultyId)
        if (selectedFacultyId === null || selectedFacultyId === undefined)
            return;
        
        setIsLoading(true);
        sessionsOfFacultyQuery.refetch().unwrap().then((fulfilled) => {
            setIsLoading(false);
            setFacultiesData(fulfilled.data);
        }).catch((error) => {
            console.error(error);
            setIsLoading(false);
        });
    }, [selectedFacultyId])

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }
    
    function printSessionSchedule(sessionId) {
        setFileIsLoading(true);
        fetch(`/Reports/SessionSchedule?sessionId=${sessionId}`,
            {
                method: "GET",
                headers: { "semesterid": localStorage.getItem("selectedSemesterId"), "Content-Type": "application/json", "Authorization": `Bearer ${localStorage.getItem('token')}`}
            }).then(response => {
            setFileIsLoading(false);
            return response.blob();
        }).then(response => {
            const url = window.URL.createObjectURL(
                new Blob([response]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `Расписание.pdf`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        })
    }

    function printSessionRetakeSchedule(sessionId) {
        setFileIsLoading(true);
        fetch(`/Reports/SessionRetakesSchedule?sessionId=${sessionId}`,
            {
                method: "GET",
                headers: { "semesterid": localStorage.getItem("selectedSemesterId"), "Content-Type": "application/json", "Authorization": `Bearer ${localStorage.getItem('token')}`}
            }).then(response => {
            setFileIsLoading(false);
            return response.blob();
        }).then(response => {
            const url = window.URL.createObjectURL(
                new Blob([response]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `Расписание пересдач.pdf`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        })
    }

    return (
        <GeneralLayout>
            <PageTitle title={'Управление сессиями'} />

            <Container>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={fileIsLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {faculties === null || faculties === undefined || isLoading ? <CircularProgress /> : <></>}
                            Управление сессиями
                        </Typography>
                    </Stack>

                    {isLoading && selectedFacultyId === '0'
                    ? <Alert color={"secondary"}>Загрузка сведений по всем факультетам обычно занимает около 30 секунд</Alert>
                    : <></>}

                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Stack direction={"row"} fullWidth spacing={2} alignItems={"center"}>
                                    <Typography>Факультет</Typography>
                                    
                                    <Select fullWidth size={"small"} 
                                            onChange={(event) => { setSelectedFacultyId(event.target.value) }}>
                                        <MenuItem value={'0'} key={'Все'}>Все факультеты</MenuItem>
                                        {faculties?.data.map((faculty) => {
                                            return <MenuItem value={faculty.key} key={faculty.value}>{faculty.value}</MenuItem>
                                        })}
                                    </Select>
                                    
                                    <FormControlLabel control={<Checkbox checked={onlyApproval} 
                                                                         onChange={(event, value) => {
                                                                             setOnlyApproval(value);
                                                                         }}/>} 
                                                      label={'Показывать только заявки на утверждение'}/>
                                    
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>

                    {facultiesData.length > 0
                    ? <Card>
                            <CardContent>
                                {facultiesData.filter((faculty) => {
                                    return faculty.sessions.find((s) => !onlyApproval || s.approveStatus === 1 && isUmu || s.approveStatus === 2 && isDispatcher) !== undefined;
                                }).map((faculty) => {
                                    return <>
                                        <Typography variant={"h4"}>{faculty.facultyName}</Typography>
                                        <Table size={"small"}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Курс</TableCell>
                                                    <TableCell>Образовательная программа</TableCell>
                                                    <TableCell>Информация</TableCell>
                                                    <TableCell>Операции</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {faculty.sessions?.filter((s) => !onlyApproval || s.approveStatus === 1 && isUmu || s.approveStatus === 2 && isDispatcher).map((item) => {
                                                    return (<TableRow hover>
                                                        <TableCell>{item.courseNumber}</TableCell>
                                                        <TableCell>
                                                            {item.programOfEducationName}
                                                            <Typography fontSize="x-small">{item.groups.join(', ')}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Stack direction="row" alignItems="center">
                                                                {
                                                                    item.sessionId === null
                                                                        ? <>Сессия не создана</>
                                                                        : <>
                                                                            Зачетная неделя:<br/>
                                                                            {item.checksStartDate} - {item.checksEndDate} <br/><br/>

                                                                            Экзамены:<br/>
                                                                            {item.examsStartDate} - {item.examsEndDate}<br/><br/>

                                                                            Каникулы:<br/>
                                                                            {item.holidayStartDate} - {item.holidayEndDate}
                                                                        </>
                                                                }
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                item.sessionId !== null
                                                                    ? <>
                                                                        {item.approveStatus >= 99 // approved
                                                                            ? <>
                                                                                <Button startIcon={<ArticleIcon/>}
                                                                                        href={`/managestatements/${item.sessionId}`}>Подробности</Button> <br/>
                                                                                <Button startIcon={<PrintIcon/>} onClick={()=>printSessionSchedule(item.sessionId)}>Печать
                                                                                    расписания</Button> <br/>
                                                                                <Button startIcon={<PrintIcon/>} onClick={()=>printSessionRetakeSchedule(item.sessionId)}>Печать
                                                                                    расписания пересдач</Button> <br/>
                                                                            </>
                                                                            : <></>
                                                                        }
                                                                        {item.approveStatus === 1 && isUmu || item.approveStatus === 2 && isDispatcher
                                                                            ? <>
                                                                                <Button startIcon={<CheckIcon/>}
                                                                                        href={`/session-approve/${item.sessionId}`}>Утвердить/Отклонить</Button> <br/>
                                                                            </>
                                                                            : <></>}
                                                                        {item.approveStatus === 0
                                                                            ? <>Не отправлено на утверждение</>
                                                                            : <></>}

                                                                        {item.approveStatus === 2 && !isDispatcher
                                                                            ? <>Отправлено на утверждение в диспетчерскую</>
                                                                            : <></>}

                                                                        {item.approveStatus === 3
                                                                            ? <>Требуются изменения по решению диспетчерской</>
                                                                            : <></>}

                                                                        {item.approveStatus === 4
                                                                            ? <>Запрос на прерывание отправлен в УМУ</>
                                                                            : <></>}

                                                                        {item.approveStatus === 5
                                                                            ? <>Запрос на прерывание отправлен в диспетчерскую</>
                                                                            : <></>}

                                                                        {item.approveStatus === 6
                                                                            ? <>Запрос на прерывание отправлен в УМУ и диспетчерскую</>
                                                                            : <></>}
                                                                    </>
                                                                    : <>Сессия не создана</>
                                                            }
                                                        </TableCell>
                                                    </TableRow>)
                                                })}
                                            </TableBody>
                                        </Table>
                                    <br /></>
                                })}
                            </CardContent>
                        </Card>
                    : <></>}
                </Stack>
            </Container>
        </GeneralLayout>
    );
}