import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer, CardContent, Button, CircularProgress, Box, Checkbox, Divider, CardHeader,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Alert, AlertTitle} from "@mui/lab";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Add, Remove} from "@mui/icons-material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {useGetFacultyGroupsOfSessionQuery, useGetFacultyGroupsQuery} from "../store/faculty/facultyApi";
import {useGetStudentsInGroupQuery} from "../store/group/groupApi";
import moment from "moment";
import {format} from "date-fns";
import OutsidePlanDisciplineInfoBlock from "../sections/@dashboard/sessions/OutsidePlanDisciplineInfoBlock";
import {
    useCreateOutsidePlanStatementsMutation,
    useResultsOfStudentsForOutsidePlanPassingQuery
} from "../store/session/sessionApi";
import {useListEmployeesQuery} from "../store/employee/employeeApi";
import PageTitle from "../components/PageTitle";

export default function CreateOutsidePlanPassingPage() {
    const {sessionId} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const [orderNumber, setOrderNumber] = useState('');
    const [eventDateTime, setEventDateTime] = useState(null);
    const [eventPlace, setEventPlace] = useState('');
    const [isIndividualSchedule, setIsIndividualSchedule] = useState(false);
    const [isBadGradesRetake, setIsBadGradesRetake] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [isSingleDate, setIsSingleDate] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const {data: groupsRequest} = useGetFacultyGroupsOfSessionQuery({sessionId});
    const {data: studentsRequest} = useGetStudentsInGroupQuery({groupId: parseInt(selectedGroup?.key, 10), atMoment: formatDate(new Date())});
    const {data: chairEmployees} = useListEmployeesQuery();
    const [students, setStudents] = useState(null);
    const [groups, setGroups] = useState(null);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [disciplines, setDisciplines] = useState(null);
    const [disciplinesRequestParams, setDisciplinesRequestParams] = useState({});
    const disciplinesRequest = useResultsOfStudentsForOutsidePlanPassingQuery(disciplinesRequestParams);
    const [createOutsidePlanStatements] = useCreateOutsidePlanStatementsMutation();
    const newStatements = [];
    
    useEffect(() => {
        if (groupsRequest !== null && groupsRequest !== undefined && (groups === null || groups.length === 0)) {
            setGroups(copyObject(groupsRequest.data));
        }
        
        if (studentsRequest?.data === null || studentsRequest === undefined || studentsRequest.data === undefined)
            return;
        
        if (students === null || students === undefined || students.length === 0) {
            setStudents(copyObject(studentsRequest.data));
        }
        
        if (disciplinesRequest?.data?.data === null || disciplinesRequest === undefined || disciplinesRequest.data === undefined) {
            return;
        }
        
        if (disciplines === null || disciplines === undefined || disciplines.length === 0) {
            setDisciplines(copyObject(disciplinesRequest.data.data));
        }
    }, [studentsRequest, groupsRequest, disciplinesRequest]);
    
    function onNewStatementRemoved(statement) {
        console.log('statement removed', statement);
        newStatements.splice(newStatements.indexOf(statement), 1);
    }
    
    function onNewStatementUpdated(statement) {
        console.log('statement updated', statement);
        // newStatements[newStatements.indexOf(statement)] = newStatements;
    }

    function onNewStatementCreated(statement) {
        console.log('statement created', statement);
        newStatements.push(statement);
    }
    
    function applyVisualChanges() {
        setRefresh(!refresh);
    }

    function copyObject(obj) {
        return JSON.parse(JSON.stringify(obj));
    }
    
    function fetchStudents() {
        if (selectedGroup === null) {
            alert(`Сперва выберите группу из списка`);
            return;
        }
        
        students.refetch();
    }
    
    function fetchDisciplines() {
        if (selectedStudents === null || selectedStudents.length === 0) {
            alert('Сперва выберите студентов из списка');
            return;
        }
        
        setDisciplinesRequestParams({
            individualSchedule: isIndividualSchedule,
            badGradesRetake: isBadGradesRetake,
            studentIds: selectedStudents.map((item) => item.key).join(','),
            sessionId})
    }
    
    function onGroupSelected(group) {
        setSelectedGroup(group);
        setSelectedStudents([]);
    }
    
    function onStudentSelected(student) {
        if (selectedStudents.find((item) => item.key === student.key) !== undefined)
            return;
        
        selectedStudents.push(student);
        applyVisualChanges();
    }
    
    // function notPassedStudents(discipline) {
    //     const notPassed = [];
    //     for (let i = 0; i < selectedStudents.length; i+=1) {
    //         let studentMaxGrade = 1;
    //         for (let j = 0; j < discipline.statements.length; j+=1) {
    //             const studentResult = discipline.statements[j].find((item) => item.studentId === selectedStudents[i].studentId);
    //             if (studentResult !== undefined) {
    //                 const convertedGradeId = studentResult.gradeId > 5 ? 2 : studentResult.gradeId;
    //                 if (convertedGradeId > studentMaxGrade)
    //                     studentMaxGrade = convertedGradeId;
    //             }
    //         }
    //        
    //         if (studentMaxGrade === 2) {
    //             notPassed.push(selectedStudents[i]);
    //         }
    //     }
    //    
    //     return notPassed;
    // }
    
    function removeStudent(student) {
        selectedStudents.splice(selectedStudents.indexOf(student), 1);
        applyVisualChanges();
    }

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const handleSave = async () => {
        console.log('New statements:', newStatements);
        if (isSingleDate) {
            for (let i = 0; i < newStatements.length; i += 1) {
                newStatements[i].eventDateTime = eventDateTime;
                newStatements[i].eventPlace = eventPlace;
            }
        }
        
        setIsSaving(true);
        
        const request = {
            data: {
                orderNumber,
                isIndividualSchedule,
                isBadGradesRetake,
                groupId: parseInt(selectedGroup.key, 10),
                statements: newStatements.map((statement) => {
                    return {
                        sessionDisciplineId: statement.sessionDisciplineId,
                        statementNumber: statement.statementNumber,
                        eventDateTime: statement.eventDateTime,
                        eventPlace: statement.eventPlace,
                        employees: statement.employees.map((e) => e.employeeId),
                        students: statement.students.map((s) => s.studentId)
                    }
                })
            }
        }
        await createOutsidePlanStatements(request).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                navigate(`/outside-plan-passing-list/${sessionId}`);
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });

                setIsSaving(false);
            }
        });
    };

    return (
        <GeneralLayout>
            <PageTitle title={'Создание заявки на внеплановую сдачу сессии'} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {groups === null || groups === undefined ? <CircularProgress /> : <></>}

                            Создание заявки на внеплановую сдачу сессии
                        </Typography>
                    </Stack>

                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                    <Typography>
                                        <strong>Номер распоряжения </strong>
                                    </Typography>

                                    <TextField placeholder={"Номер распоряжения"}
                                               value={orderNumber}
                                               disabled={disciplines !== null}
                                               onInput={(event) => setOrderNumber(event.target.value)}
                                               size={"small"}/>
                                    
                                    <FormControlLabel control={<Checkbox size={"small"} 
                                                                         value={isIndividualSchedule}
                                                                         disabled={disciplines !== null}
                                                                         onChange={(event, checked) => {
                                                                             setIsIndividualSchedule(checked);
                                                                         }}/>} 
                                                      label={`Индивидуальный график`} />
                                    
                                    <FormControlLabel control={<Checkbox size={"small"}
                                                                         value={isBadGradesRetake}
                                                                         disabled={disciplines !== null}
                                                                         onChange={(event, checked) => {
                                                                             setIsBadGradesRetake(checked);
                                                                         }}/>} 
                                                      label={`Пересдача оценок "3" и "4"`} />
                                </Stack>

                                {isIndividualSchedule
                                ? <Alert color={"info"}>
                                        <AlertTitle>Информация об индивидуальном графике</AlertTitle>
                                        <Typography fontSize={"small"}>Внеплановые ведомости, созданные с пометкой "Индивидуальный график", могут быть открыты на любую дисциплину в семестре, даже если ведомость на обучающегося уже открыта. При таких условиях обучающийся удаляется из открытой ведомости и остается только во внеплановой ведомости с пометкой «Индивидуальный график».
                                            Если ведомость основной сессии уже закрыта (с оценкой «неявка», «неудовлетворительно», «не зачтено») или еще не создана, то внеплановая ведомость создается как обычно и обучающийся не удаляется. При наличии открытой внеплановой ведомости или закрытой с положительной оценкой, во всех последующих ведомостях основной сессии обучающийся не появляется.
                                        </Typography>
                                  </Alert>
                                : <></>}
                                
                                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                    <Typography><strong>Группа</strong></Typography>
                                    <Select size={"small"}
                                            style={{'min-width': '150px'}}
                                            value={selectedGroup}
                                            disabled={disciplines !== null}
                                            onChange={(event) => onGroupSelected(event.target.value)}>
                                        {groups?.sort((left, right) => left.value >= right.value ? 1 : -1).map((item) => {
                                            return <MenuItem value={item} key={item.value}>{item.value}</MenuItem>
                                        })}
                                    </Select>
                                </Stack>

                                <Card variant={"outlined"}>
                                    <CardContent>
                                        <Stack direction={"row"} spacing={2} alignItems={'center'}>
                                            <Typography variant={"h6"}>Дата, время, место проведения</Typography>
                                            <FormControlLabel control={
                                                <Checkbox size={"small"} 
                                                          checked={isSingleDate} 
                                                          onChange={(event, checked) => setIsSingleDate(checked)}
                                                          disabled={disciplines !== null} />
                                                            } 
                                                              label={`Сдача одной датой`} />
                                        </Stack>
                                        {isSingleDate
                                        ? <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                                <Typography><strong>Дата и время проведения</strong></Typography>
                                                <TextField size={"small"} 
                                                           type={"datetime-local"}
                                                           value={eventDateTime}
                                                           disabled={disciplines !== null}
                                                           onInput={(event) => setEventDateTime(event.target.value)}/>

                                                <Divider />

                                                <Typography><strong>Место</strong></Typography>
                                                <TextField size={"small"}
                                                           value={eventPlace}
                                                           disabled={disciplines !== null}
                                                           onInput={(event) => setEventPlace(event.target.value)}/>
                                            </Stack>
                                        : <></>}
                                    </CardContent>
                                </Card>

                                {students === null || students === undefined || studentsRequest?.data === null || studentsRequest?.data === undefined
                                ? <Button variant={"contained"} disabled={selectedGroup === null} onClick={() => fetchStudents()}>Следующий шаг</Button>
                                : <>
                                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                            <Typography><strong>Студенты</strong></Typography>
                                            <Select style={{"min-width": "300px"}} size={"small"} 
                                                    onChange={(event) => onStudentSelected(event.target.value)}
                                                    disabled={disciplines !== null}>
                                                {students.sort((left, right) => {return left?.value >= right?.value ? 1 : -1}).map((student) => {
                                                    return <MenuItem value={student} key={student.value}>{student.value}</MenuItem>
                                                })}
                                            </Select>
                                        </Stack>
                                        
                                        <ol>
                                            {selectedStudents?.map((selectedStudent) => {
                                                return <li>{selectedStudent.value} <Button startIcon={<RemoveCircleIcon/>}
                                                                                           color={"error"}
                                                                                           disabled={disciplines !== null}
                                                                                           onClick={() => removeStudent(selectedStudent)}/></li>
                                            })}
                                        </ol>

                                        {disciplines !== null
                                        ? <></>
                                        : <Button variant={"contained"} 
                                                  disabled={selectedStudents === null || selectedStudents.length === 0}
                                                  onClick={() => fetchDisciplines()}>Продолжить</Button>}
                                        
                                        <Alert color={"info"}>
                                            Заполните поля выше, выберите студентов и нажмите кнопку "Продолжить". Обратите внимание, что для опции "Пересдача оценок 3 и 4" допускается выбор только одного студента
                                        </Alert>
                                </>}

                                {disciplines !== null
                                ? <>
                                        <Typography variant={"h4"}>Экзамены</Typography>
                                        {disciplines.find((item) => item.controlType === 0) === undefined
                                            ? <Typography>Нет записей</Typography>
                                            : <></>}
    
                                        {disciplines.filter((item) => item.controlType === 0).map((item) => {
                                            return <>
                                                <OutsidePlanDisciplineInfoBlock discipline={item} 
                                                                                selectedStudents={selectedStudents} 
                                                                                chairEmployees={chairEmployees?.data}
                                                                                individualSchedule={isIndividualSchedule}
                                                                                badGradesRetake={isBadGradesRetake}
                                                                                editableDateAndPlace={!isSingleDate}
                                                                                onNewStatementCreated={(input) => onNewStatementCreated(input)}
                                                                                onNewStatementUpdated={(input) => onNewStatementUpdated(input)}
                                                                                onNewStatementRemoved={(input) => onNewStatementRemoved(input)}/>
    
                                                <Divider /></>
                                        })}

                                        <Typography variant={"h4"}>Зачёты</Typography>
                                        {disciplines.find((item) => item.controlType === 1 || item.controlType === 2) === undefined
                                        ? <Typography>Нет записей</Typography>
                                        : <></>}
                                        
                                        {disciplines.filter((item) => item.controlType === 1 || item.controlType === 2).map((item) => {
                                            return <>
                                                <OutsidePlanDisciplineInfoBlock discipline={item} 
                                                                                selectedStudents={selectedStudents} 
                                                                                chairEmployees={chairEmployees?.data}
                                                                                individualSchedule={isIndividualSchedule}
                                                                                badGradesRetake={isBadGradesRetake}
                                                                                editableDateAndPlace={!isSingleDate}
                                                                                onNewStatementCreated={(input) => onNewStatementCreated(input)}
                                                                                onNewStatementUpdated={(input) => onNewStatementUpdated(input)}
                                                                                onNewStatementRemoved={(input) => onNewStatementRemoved(input)}/>

                                                <Divider /></>
                                        })}

                                        <Typography variant={"h4"}>Курсовые работы</Typography>
                                        {disciplines.find((item) => item.controlType === 3 || item.controlType === 4) === undefined
                                            ? <Typography>Нет записей</Typography>
                                            : <></>}

                                        {disciplines.filter((item) => item.controlType === 3 || item.controlType === 4).map((item) => {
                                            return <>
                                                <OutsidePlanDisciplineInfoBlock discipline={item} 
                                                                                selectedStudents={selectedStudents} 
                                                                                chairEmployees={chairEmployees?.data}
                                                                                individualSchedule={isIndividualSchedule}
                                                                                badGradesRetake={isBadGradesRetake}
                                                                                editableDateAndPlace={!isSingleDate}
                                                                                onNewStatementCreated={(input) => onNewStatementCreated(input)}
                                                                                onNewStatementUpdated={(input) => onNewStatementUpdated(input)}
                                                                                onNewStatementRemoved={(input) => onNewStatementRemoved(input)}/>

                                                <Divider /></>
                                        })}
                                  </>
                                : <></>}

                                {disciplines === null || disciplines === undefined || disciplines.length === 0
                                ? <></>
                                : isSaving
                                    ? <><CircularProgress /></>
                                    : <>
                                            <Divider /><Button variant={"contained"} onClick={() => handleSave()}>Сохранить внеплановую сдачу</Button>
                                    </>}
                            </Stack>
                        </CardContent>
                    </Card>
                    <br />
                </Stack>
            </Container>
        </GeneralLayout>
    );
}