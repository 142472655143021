import {Link, useNavigate, useParams} from "react-router-dom";
import {useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Button,
    ButtonGroup,
    Card,
    CardContent,
    CircularProgress,
    Container,
    Stack,
    Table, TableBody, TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {GeneralLayout} from "../layouts/GeneralLayout";
import {useGetSessionDataForApprovingQuery, usePostSendSessionForApprovalMutation} from "../store/session/sessionApi";
import PageTitle from "../components/PageTitle";

export default function ApproveSessionPage() {
    const navigate = useNavigate();
    const {id} = useParams();
    const [refresh, setRefresh] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const {data} = useGetSessionDataForApprovingQuery({sessionId: id});
    const [sendSessionForApproval] = usePostSendSessionForApprovalMutation();

    const handleSave = async () => {
        setIsSaving(true);

        await sendSessionForApproval({sessionId: id}).unwrap().then((fulfilled) => {
            if (fulfilled.isSuccess)
                navigate('/sessions');
            else {
                alert(fulfilled.message);
                setIsSaving(false);
            }
        });
    };

    return <GeneralLayout>
        <PageTitle title={'Утверждение сессии'} />

        <Container>
            <Stack
                padding="120px 0px 0px 100px"
                direction="column"
                spacing={2}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Утверждение сессии {
                        data?.courseName !== null && data?.courseName !== undefined
                            ? <>для {data?.courseName}</>
                            : <CircularProgress />
                    }
                    </Typography>
                </Stack>
                
                <Stack alignItems="right" justifyContent="space-between" alignContent="right" textAlign="right">
                    <Typography><strong>Зачетная неделя</strong> {data?.checksStartDate} — {data?.checksEndDate}</Typography>
                    <Typography><strong>Экзаменационная сессия</strong> {data?.examsStartDate} — {data?.examsEndDate}</Typography>
                    <Typography><strong>Каникулы</strong> {data?.holidaysStartDate} — {data?.holidaysEndDate}</Typography>
                </Stack>

                <Card>
                    <CardContent>
                        <Stack spacing={1}>
                            <Typography variant="h5">Экзамены</Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell rowSpan={2}>Наименование дисциплины</TableCell>
                                        <TableCell rowSpan={2}>Ф.И.О. экзаменатора</TableCell>
                                        <TableCell colSpan={data?.disciplines.filter(discipline => {
                                            return discipline.controlType === 0
                                        })[0]?.groups?.length} align="center">Группа</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {data?.disciplines.filter(discipline => {
                                            return discipline.controlType === 0
                                        })[0]?.groups?.filter((group) => {
                                            return group.passingType === 0
                                        }).map((group) => {
                                            return <TableCell align="center">{group.groupName}</TableCell>
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data?.disciplines.filter((discipline) => {
                                        return discipline.controlType === 0;
                                    }).map((discipline) => {
                                        return <TableRow>
                                            <TableCell>{discipline.disciplineName}</TableCell>
                                            <TableCell>{discipline.employees.join(', ')}{discipline?.extraEmployees?.length > 0 ? `, ${discipline.extraEmployees.join(', ')}` : ''}</TableCell>
                                            {discipline.groups?.filter((group) => {
                                                return group.passingType === 0
                                            }).map((group) => {
                                                return <TableCell align="center">
                                                    {group.eventDate} <br/>
                                                    {group.eventPlace}
                                                </TableCell>
                                            })}
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>

                            <br/>

                            <Typography variant="h5">Зачёты</Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell rowSpan={2}>Наименование дисциплины</TableCell>
                                        <TableCell rowSpan={2}>Ф.И.О. экзаменатора</TableCell>
                                        <TableCell colSpan={data?.disciplines.filter(discipline => {
                                            return discipline.controlType === 1
                                        })[0]?.groups?.length} align="center">Группа</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {data?.disciplines.filter(discipline => {
                                            return discipline.controlType === 1
                                        })[0]?.groups?.filter((group) => {
                                            return group.passingType === 0
                                        }).map((group) => {
                                            return <TableCell align="center">{group.groupName}</TableCell>
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data?.disciplines.filter((discipline) => {
                                        return discipline.controlType === 1;
                                    }).map((discipline) => {
                                        return <TableRow>
                                            <TableCell>{discipline.disciplineName}</TableCell>
                                            <TableCell>{discipline.employees.join(', ')}{discipline?.extraEmployees?.length > 0 ? `, ${discipline.extraEmployees.join(', ')}` : ''}</TableCell>
                                            {discipline.groups?.filter((group) => {
                                                return group.passingType === 0
                                            }).map((group) => {
                                                return <TableCell align="center">
                                                    {group.eventDate} <br/>
                                                    {group.eventPlace}
                                                </TableCell>
                                            })}
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>

                            <br/>

                            <Typography variant="h5">Дифф. зачёты</Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell rowSpan={2}>Наименование дисциплины</TableCell>
                                        <TableCell rowSpan={2}>Ф.И.О. экзаменатора</TableCell>
                                        <TableCell colSpan={data?.disciplines.filter(discipline => {
                                            return discipline.controlType === 2
                                        })[0]?.groups?.length} align="center">Группа</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {data?.disciplines.filter(discipline => {
                                            return discipline.controlType === 2
                                        })[0]?.groups?.filter((group) => {
                                            return group.passingType === 0
                                        }).map((group) => {
                                            return <TableCell align="center">{group.groupName}</TableCell>
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data?.disciplines.filter((discipline) => {
                                        return discipline.controlType === 2;
                                    }).map((discipline) => {
                                        return <TableRow>
                                            <TableCell>{discipline.disciplineName}</TableCell>
                                            <TableCell>{discipline.employees.join(', ')}{discipline?.extraEmployees?.length > 0 ? `, ${discipline.extraEmployees.join(', ')}` : ''}</TableCell>
                                            {discipline.groups?.filter((group) => {
                                                return group.passingType === 0
                                            }).map((group) => {
                                                return <TableCell align="center">
                                                    {group.eventDate} <br/>
                                                    {group.eventPlace}
                                                </TableCell>
                                            })}
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>

                            <br/>
                            <hr/>

                            <Typography variant="h5">Курсовые работы(проекты)</Typography>
                            <ol>
                                {
                                    data?.disciplines.filter((d) => {
                                        return d.controlType === 3 || d.controlType === 4;
                                    }).map((d) => {
                                        return <li>
                                            <Typography>{d.disciplineName}</Typography>
                                            <Typography fontSize={"small"}>Преподаватели: {d.employees.join(', ')}</Typography>
                                        </li>
                                    })
                                }
                            </ol>
                            
                            <br/>
                            <hr/>

                            <Typography variant="h5">Пересдачи</Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell rowSpan={2}>Наименование дисциплины</TableCell>
                                        <TableCell rowSpan={2}>Ф.И.О. экзаменатора</TableCell>
                                        <TableCell colSpan={data?.disciplines.filter(discipline => {
                                            return discipline.controlType === 1
                                        })[0]?.groups?.length} align="center">Группа</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {data?.disciplines.filter(discipline => {
                                            return discipline.controlType === 1
                                        })[0]?.groups?.filter((group) => {
                                            return group.passingType === 1
                                        }).map((group) => {
                                            return <TableCell align="center">{group.groupName}</TableCell>
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data?.disciplines.filter((d) => {
                                        return d.groups.filter((g) => {
                                            return g.passingType === 1;
                                        }).length > 0
                                    }).map((discipline) => {
                                        return <TableRow>
                                            <TableCell>{discipline.disciplineName}</TableCell>
                                            <TableCell>{discipline.employees.join(', ')}{discipline?.extraEmployees?.length > 0 ? `, ${discipline.extraEmployees.join(', ')}` : ''}</TableCell>
                                            {discipline.groups?.filter((group) => {
                                                return group.passingType === 1
                                            }).map((group) => {
                                                return <TableCell align="center">
                                                    {group.eventDate} <br/>
                                                    {group.eventPlace}
                                                </TableCell>
                                            })}
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                            
                            <br />
                            
                            

                            <Stack alignItems="center" fullWidth>
                                {
                                    !isSaving
                                        ? <>
                                            <Button variant="contained"
                                                    onClick={() => {handleSave()}}
                                            fullWidth>Отправить на утверждение</Button>
                                            <Button href={`/sessions`}
                                            fullWidth>Назад</Button>
                                        </>
                                        : <>Сохранение <CircularProgress /></>
                                }
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
        </Container>
    </GeneralLayout>
}