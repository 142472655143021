import {Link, useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {
    Button, ButtonGroup,
    Card,
    CardContent, CircularProgress,
    Container,
    Stack,
    Table, TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Select from "@mui/material/Select";
import {Option} from "@mui/joy";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import EditIcon from "@mui/icons-material/Edit";
import {useGetFacultyCoursesQuery} from "../store/faculty/facultyApi";
import {useEffect, useState} from "react";
import {useGetFacultyAttestationsQuery} from "../store/attestation/attestationApi";
import PageTitle from "../components/PageTitle";

export default function FacultyAttestationsPage() {
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const facultyCourses = useGetFacultyCoursesQuery();
    const getFacultyAttestations = useGetFacultyAttestationsQuery({courseId: selectedCourse});
    
    useEffect(() => {
        if (selectedCourse <= 0)
            return;

        setIsLoading(true);
        getFacultyAttestations.refetch().unwrap().then((fulfilled) => {
           setIsLoading(false);
           setData(fulfilled);
        });
    }, [selectedCourse])

    const onCourseChanged = async (value) => {
        setSelectedCourse(value);
    }

    function toDateString(date){
        return new Date(date).toLocaleDateString("ru-RU");
    }

    return <GeneralLayout>
        <PageTitle title={`Список аттестаций`} />

        <Container>
            <Stack
                padding="120px 0px 0px 100px"
                direction="column"
                spacing={6}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography variant="h4" gutterBottom>
                        Список аттестаций
                    </Typography>
                </Stack>
                
                <Card>
                    <CardContent>
                        <Stack direction="row" spacing={3}>
                            <Typography alignSelf="center">Курс</Typography>

                            <Select 
                                    onChange={(event) => { onCourseChanged(event.target.value); }}
                                    fullWidth>
                                {facultyCourses?.data?.data?.map((item) => {
                                    return <MenuItem value={item.key}>{item.value}</MenuItem>
                                })}
                            </Select>
                            
                            <Button startIcon={<AddIcon />}
                                    disabled={selectedCourse === null}
                                    href={`/createattestation/${selectedCourse}`}
                                    variant="contained">Создать аттестацию</Button>
                        </Stack>
                    </CardContent>
                </Card>

                <Card>
                    {data !== null && data !== undefined
                    ? <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>№ аттестации</TableCell>
                                    <TableCell>Период</TableCell>
                                    <TableCell>Операции</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.attestations?.map((item) => {
                                    return <TableRow>
                                        <TableCell>{item.attestationNumber}</TableCell>
                                        <TableCell>{toDateString(item.startDate)} - {toDateString(item.endDate)}</TableCell>
                                        <TableCell>
                                            <ButtonGroup orientation="vertical">
                                                <Button startIcon={<InsertDriveFileOutlinedIcon />}
                                                        href={`/attestationdetails/${item.attestationId}`}>Аттестационная ведомость</Button>
                                                <Button startIcon={<EditIcon />}
                                                        href={`/editattestation/${item.attestationId}`}>Редактировать аттестацию</Button>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    : <CardContent>
                            <Typography align="center">
                                {isLoading 
                                ? <CircularProgress />
                                : <>Выберите курс из списка выше</>}
                            </Typography>
                        </CardContent>}
                </Card>
            </Stack>
        </Container>
    </GeneralLayout>
}