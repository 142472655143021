import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer, CardContent, Button, CircularProgress, Box,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import React, {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import JournalComponent from "../components/journal/JournalComponent";
import MainLayout from "./main";

import './disciplinegroupjournal/index.scss';
import PageTitle from "../components/PageTitle";

export default function VirtualGroupJournalPage() {
    // Looks like this is the obsolete page
    const {virtualGroupId} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const data = null;

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    return (
        <MainLayout>
            <PageTitle title={`Журнал сводной группы`}/>

            <main id="journal">
                <section>
                    <div className={'container'}>
                        <Stack direction="column"
                               padding="30px 0px 0px 0px"
                               spacing={2}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                                <Typography variant="h4" gutterBottom>
                                    Журнал сводной группы
                                </Typography>
                            </Stack>

                            <JournalComponent groupName={"Сводная группа"}
                                              groupFlowBindingId={0}
                                              virtualGroupId={parseInt(virtualGroupId, 10)}/>
                        </Stack>
                    </div>
                </section>
            </main>
        </MainLayout>
    );
}