import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer, CardContent, Button, CircularProgress, Box, TableCell, TableRow, TableBody, Table, ButtonGroup,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import {
    useApproveOutsidePlanPassingMutation,
    useGetOutsidePlanPassingApprovalDetailsQuery
} from "../store/session/sessionApi";
import PageTitle from "../components/PageTitle";

export default function ApproveOutsidePlanPassingRequestPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const [review, setReview] = useState('');
    const {data} = useGetOutsidePlanPassingApprovalDetailsQuery({id});
    const [approveOutsidePlanPassingRequest] = useApproveOutsidePlanPassingMutation();

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }
    
    function getDisciplinesByControlType(controlType) {
        const disciplines = data?.data?.disciplines?.filter((item) => 
            controlType === 0 
                ? item.controlType === 0
                : controlType === 1
                    ? item.controlType === 1 || item.controlType === 2
                    : item.controlType === 3 || item.controlType === 4);
        
        if (disciplines === null || disciplines === undefined || disciplines.length === 0)
            return <>Нет записей</>;
        
        return (
            <Table>
                <TableBody>
                    {disciplines.map((item) => {
                        return <TableRow>
                            <TableCell>{item.disciplineName}</TableCell>
                            <TableCell>{item.employees.join(', ')}</TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        )
    }

    const handleSave = async (approved) => {
        setIsSaving(true);

        await approveOutsidePlanPassingRequest({data: {outsidePlanPassingId: id, review, approved}}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                navigate('/outside-plan-passings-approval-list', {
                    replace: true
                });
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });

                setIsSaving(false);
            }
        });
    };

    return (
        <GeneralLayout>

            <PageTitle title={'Заявка на внеплановую сдачу сессии'} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress /> : <></>}
                            Заявка на внеплановую сдачу сессии
                        </Typography>
                    </Stack>

                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Typography><strong>Факультет: </strong> {data?.data?.facultyName}</Typography>
                                <Typography><strong>Курс: </strong>  {data?.data?.courseName}</Typography>
                                <Typography><strong>Номер приказа: </strong>  {data?.data?.orderNumber}</Typography>
                                
                                <Typography variant={"h6"}>Список студентов</Typography>
                                <Table>
                                    <TableBody>
                                        {data?.data?.students.map((item) => {
                                            return <TableRow>
                                                <TableCell>{item.lastName} {item.firstName} {item.middleName}</TableCell>
                                                <TableCell>{item.groupName}</TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>

                                <Typography variant={"h6"}>Дисциплины</Typography>
                                
                                <Stack>
                                    <Typography><strong>Экзамены</strong></Typography>
                                    {getDisciplinesByControlType(0)}
                                </Stack>

                                <Stack>
                                    <Typography><strong>Зачёты</strong></Typography>
                                    {getDisciplinesByControlType(1)}
                                </Stack>

                                <Stack>
                                    <Typography><strong>Курсовые работы/проекты</strong></Typography>
                                    {getDisciplinesByControlType(3)}
                                </Stack>

                                <Typography variant={"h6"}>Замечания</Typography>
                                <TextField multiline
                                           onInput={(event) => setReview(event.target.value)}
                                           maxRows={3}
                                           minRows={3} />
                                
                                <Stack justifyContent="space-between">
                                    {
                                        !isSaving
                                            ? <Stack direction={"row"} spacing={1}>
                                                <Button color={"success"}
                                                        variant={"contained"} 
                                                        disabled={data === null || data === undefined}
                                                        onClick={() => handleSave(true)}>Утвердить</Button>
                                                <Button color={"error"}
                                                        variant={"contained"}
                                                        disabled={data === null || data === undefined}
                                                        onClick={() => handleSave(false)}>Отклонить</Button>
                                            </Stack>  
                                            : <CircularProgress/>
                                    }
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}