import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer, CardContent, Button, CircularProgress, Box, Table, TableHead, TableRow, TableCell, TableBody,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import {RemoveDone, RemoveFromQueue, RemoveSharp} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {useGetFacultyGroupsQuery} from "../store/faculty/facultyApi";
import {useGetCertificatesOfSkippingQuery, useRemoveCertificateOfSkippingMutation} from "../store/document/documentApi";
import PageTitle from "../components/PageTitle";

export default function CertificatesOfSkippingPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const {data: groups} = useGetFacultyGroupsQuery();
    const [copyOfGroups, setCopyOfGroups] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const {data: certificates} = useGetCertificatesOfSkippingQuery({groupId: selectedGroupId});
    const [removeCertificate] = useRemoveCertificateOfSkippingMutation();
    const [copyOfCertificates, setCopyOfCertificates] = useState([]);
    const [studentFilter, setStudentFilter] = useState('');

    useEffect(() => {
        if (groups !== null && groups !== undefined && copyOfGroups.length === 0) {
            setCopyOfCertificates([]);
            setCopyOfGroups(copyObject(groups));
        }

        if (certificates !== null && certificates !== undefined && copyOfCertificates.length === 0) {
            setCopyOfCertificates(copyObject(certificates));
        }
    }, [groups, certificates])

    function copyObject(obj) {
        return JSON.parse(JSON.stringify(obj));
    }
    
    function studentNames() {
        if (copyOfCertificates === null || copyOfCertificates === undefined || copyOfCertificates.length === 0)
            return [];
        
        return distinct(copyOfCertificates?.documents.map((item) => item.studentFullName));
    }

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }
    
    function certificateTypeName(id) {
        switch (id) {
            case 0: return 'Справка';
            case 1: return 'Распоряжение деканата';
            case 2: return 'Заявление';
            case 3: return 'График дежурств';
            default: return '-';
        }
    }

    function toDateString(date){
        if (date === null || date === undefined)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }
    
    function distinct(arr) {
        const result = [];
        for (let i = 0; i < arr.length; i+=1) {
            const existing = result.find((item) => item === arr[i]);
            if (existing === undefined)
                result.push(arr[i]);
        }
        
        return result;
    }
    
    function handleGroupSelected(groupId) {
        setCopyOfCertificates([]);
        setSelectedGroupId(groupId);
    }
    
    async function removeCertificateHandler(certificateOfSkippingId, studentId) {
        const certificate = copyOfCertificates?.documents.find((item) => item.certificateOfSkippingId === certificateOfSkippingId 
            && item.studentId === studentId);
        console.log('Нашли элемент', certificate)
        copyOfCertificates?.documents.splice(certificates?.documents.indexOf(certificate), 1);
        
        await removeCertificate({data : {certificateOfSkippingId, studentId}}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                console.log('Удалено')
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });

                setIsSaving(false);
            }
        });
    }

    return (
        <GeneralLayout>
            <PageTitle title={'Справки о пропусках'} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {groups === null || groups === undefined ? <CircularProgress /> : <></>}
                            Справки о пропусках
                        </Typography>
                    </Stack>
                    
                    <Card>
                        <CardContent>
                            <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
                                <Stack spacing={1} width={150}>
                                    <Typography>Группа</Typography>
                                    <Select size={"small"} 
                                            value={selectedGroupId}
                                            onChange={(event) => handleGroupSelected(event.target.value)}>
                                        {copyOfGroups?.data?.sort((left,right) => left.value > right.value ? 1 : -1)
                                            .map((item) => {
                                                return <MenuItem key={item.value} value={item.key}>{item.value}</MenuItem>
                                            })}
                                    </Select>
                                </Stack>

                                <Stack spacing={1} width={350}>
                                    <Typography>Студент</Typography>
                                    <Select size={"small"}
                                            value={studentFilter}
                                            onChange={(event) => setStudentFilter(event.target.value)}>
                                        <MenuItem key={''} value={''}>Все</MenuItem>
                                        {studentNames().sort((left,right) => left > right ? 1 : -1)
                                            .map((item) => {
                                            return <MenuItem key={item} value={item}>{item}</MenuItem>
                                        })}
                                    </Select>
                                </Stack>
                                
                                <Stack alignItems={"center"} direction={"row"}>
                                    <Button startIcon={<AddIcon />}
                                            href={`/create-certificate-of-skipping`}
                                            variant={"contained"}>Зарегистрировать документ</Button>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>

                    <Card>
                        <Table size={"small"}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Имя студента</TableCell>
                                    <TableCell>Тип документа</TableCell>
                                    <TableCell>Даты действия</TableCell>
                                    <TableCell>Комментарий</TableCell>
                                    <TableCell align={"center"}>Операции</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {copyOfCertificates?.documents?.filter((item) => {
                                    return item.studentFullName.startsWith(studentFilter);
                                }).map((item) => {
                                    return <TableRow>
                                        <TableCell>{item.studentFullName}</TableCell>
                                        <TableCell>{certificateTypeName(item.documentType)}</TableCell>
                                        <TableCell>{toDateString(item.validFromDate)} (с {item.validFromPair} пары) - {toDateString(item.validToDate)} (по {item.validToPair} пару)</TableCell>
                                        <TableCell>{item.comment}</TableCell>
                                        <TableCell align={"center"}><IconButton onClick={()=> removeCertificateHandler(item.certificateOfSkippingId, item.studentId)} size={"small"} color={"error"}><RemoveCircleIcon /></IconButton></TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </Card>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}