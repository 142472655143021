import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer, CardContent, Button, CircularProgress, Box, Table, TableHead, TableRow, TableCell, TableBody,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import ArticleIcon from "@mui/icons-material/Article";
import {usePracticesForApprovalQuery} from "../store/practice/practiceApi";
import PageTitle from "../components/PageTitle";

export default function PracticeApproveListPage() {
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const {data} = usePracticesForApprovalQuery();


    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }
    
    function controlTypeName(id) {
        return id === 2 ? 'Зачёт с оценкой' : 'Зачёт';
    }

    return (
        <GeneralLayout>
            <PageTitle title={`Заявки на утверждение практик`} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress /> : <></>}
                            Заявки на утверждение практик
                        </Typography>
                    </Stack>

                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                {data?.faculties?.length === 0
                                ? <Typography align={"center"}>Список пуст</Typography>
                                : <></>}
                                
                                {data?.faculties?.map((faculty) => {
                                    return <>
                                        <Typography variant={"h5"}>Факультет {faculty.facultyName}</Typography>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Курс</TableCell>
                                                    <TableCell>Группа</TableCell>
                                                    <TableCell>Вид, тип практики</TableCell>
                                                    <TableCell>Форма контроля</TableCell>
                                                    <TableCell>Номер приказа</TableCell>
                                                    <TableCell>Операции</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {faculty.practices.map((practice) => {
                                                    return <TableRow>
                                                        <TableCell>{practice.courseName}</TableCell>
                                                        <TableCell>{practice.groupName}</TableCell>
                                                        <TableCell>{practice.practiceKind}, {practice.practiceType}</TableCell>
                                                        <TableCell>{controlTypeName(practice.controlTypeId)}</TableCell>
                                                        <TableCell>{practice.orderNumber}</TableCell>
                                                        <TableCell>
                                                            <Button startIcon={<ArticleIcon />}
                                                                    href={`/practice-approve-info/${practice.practiceId}`}>Просмотр информации</Button>
                                                        </TableCell>
                                                    </TableRow>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </>
                                })}
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}