import {Helmet} from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer,
    CardContent,
    Button,
    CircularProgress,
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    ButtonGroup,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import {StepByStepTable} from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import IconButton from "@mui/material/IconButton";
import {EditOutlined, PanoramaFishEye, RemoveRedEyeOutlined} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import {useGetRegistryDocumentsQuery} from "../store/document/documentApi";
import PageTitle from "../components/PageTitle";
import {openFsLink} from "../utils/getCorrectFsLink";

export default function RegistryOfDocumentsPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const {data} = useGetRegistryDocumentsQuery();
    const [links, setLinks] = useState([]);

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function toDateString(date) {
        if (date === null || date === undefined)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    function getCorrectFsLink(link) {
        // костыль для того, чтобы можно было скачивать файлы из тестового хранилища

        const cachedLink = links.find((item) => item.link === link);
        if (cachedLink !== undefined) {
            console.log('Ссылка найдена в кэше')
            window.open(cachedLink.correctLink, '_blank')
            return;
        }
        console.log('ссылка не найдена в кэше, определяем корректную форму')

        try {
            fetch(link)
                .then((resp) => {
                    console.log('response', resp);
                    if (resp.status === 404) {
                        console.log(`${link} = 404`)
                        links.push({
                            link,
                            correctLink: `${link}?useTest=1`
                        })

                        window.open(`${link}?useTest=1`, '_blank')
                    }
                }).catch((resp) => {
                    console.log('error', resp)
                    links.push({
                        link,
                        correctLink: `${link}?useTest=1`
                    })
                })
        } catch (err) {
            console.log('catch', err)
            console.log(links)
        }
    }

    return (
        <GeneralLayout>
            <PageTitle title={`Реестр документов`}/>

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress/> : <></>}
                            Реестр документов
                        </Typography>
                        <Button variant={'contained'}
                                startIcon={<AddIcon/>}
                                href={`/create-document-in-registry`}>Добавить новый</Button>
                    </Stack>

                    <Card>
                        <Table size={"small"}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>№ документа</TableCell>
                                    <TableCell>Дата документа</TableCell>
                                    <TableCell>Семестры</TableCell>
                                    <TableCell>Студент</TableCell>
                                    <TableCell>Документ</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.data?.map((item, index) => {
                                    return <TableRow>
                                        <TableCell><strong>{index + 1}</strong></TableCell>
                                        <TableCell>{item.documentNumber}</TableCell>
                                        <TableCell>{toDateString(item.documentDate)}</TableCell>
                                        <TableCell>{item.semesters.join(', ')}</TableCell>
                                        <TableCell>{item.studentFullName}</TableCell>
                                        <TableCell>
                                            <ButtonGroup orientation={"horizontal"}>
                                                <IconButton
                                                    onClick={() => openFsLink(`https://fs.kubsau.ru/file/${item.registryDocumentGuid}`)}><RemoveRedEyeOutlined/></IconButton>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </Card>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}