import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from '../../utils/prepareAuthHeaders';

export const statementApi = createApi({
  reducerPath: 'statementApi',
    keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: '/Statement',
    prepareHeaders: prepareAuthHeaders,
  }),
  endpoints: (builder) => ({
        createOutsidePlanPassing: builder.mutation({
          query: ({ sessionId, orderNumber, groupId, individualPlan, isBadGradeRetake, eventDateTime, eventPlace }) => ({
            url: `/CreateOutsidePlanPassing`,
            method: 'POST',
            body: { sessionId, orderNumber, groupId, individualPlan, isBadGradeRetake, eventDateTime, eventPlace },
          }),
        }),
        createOutsidePlanStatement: builder.mutation({
          query: ({ sessionDisciplinePassingId,  statementNumber}) => ({
              url: `/CreateOutsidePlanStatement`,
              method: 'POST',
              body: { sessionDisciplinePassingId,  statementNumber },
          }),
        }),
      postEditStatementNumber: builder.mutation({
          query: ({ statementId, statementNumber }) => ({
              url: `/EditStatementNumber?statementId=${statementId}&statementNumber=${statementNumber}`,
              method: 'POST'
          }),
          invalidatesTags: ['Post'],
      }),
      getStatementInfo: builder.query({
          query: ({statementId}) => ({
              url: `/StatementInfo`,
              method: 'GET',
              params: {statementId}
          }),
      }),
      getStatementResults: builder.query({
          query: ({statementId}) => ({
              url: `/StatementResults`,
              method: 'GET',
              params: {statementId}
          }),
      }),
      getEmployeeStatements: builder.query({
          query: () => ({
              url: `/EmployeeStatements`,
              method: 'GET'
          }),
      }),
      getEmployeeStatementDetails: builder.query({
          query: ({statementId}) => ({
              url: `/EmployeeStatementDetails`,
              method: 'GET',
              params: {statementId}
          }),
      }),
      postEmployeeEditStatement: builder.mutation({
          query: ({ statementId, students }) => ({
              url: `/EmployeeEditStatement`,
              method: 'POST',
              body: {
                  statementId,
                  students
              }
          }),
          invalidatesTags: ['Post'],
      }),
      closeSessionStatement: builder.mutation({
          query: ({ data }) => ({
              url: `/CloseSessionStatement`,
              method: 'POST',
              body: data
          }),
          invalidatesTags: ['Post'],
      }),
      updateStatementGrade: builder.mutation({
          query: ({ data }) => ({
              url: `/UpdateStatementGrade`,
              method: 'POST',
              body: data
          }),
          invalidatesTags: ['Post'],
      }),
      updateStatementInfo: builder.mutation({
          query: ({ data }) => ({
              url: `/UpdateStatementInfo`,
              method: 'POST',
              body: data
          }),
          invalidatesTags: ['Post'],
      }),
      deleteStudentFromStatement: builder.mutation({
          query: ({ data }) => ({
              url: `/DeleteStudentFromStatement`,
              method: 'POST',
              body: data
          }),
          invalidatesTags: ['Post'],
      }),
  }),
});

export const {
    useCreateOutsidePlanPassingMutation,
    useCreateOutsidePlanStatementMutation,
    usePostEditStatementNumberMutation,
    useGetStatementInfoQuery,
    useGetStatementResultsQuery,
    useGetEmployeeStatementsQuery,
    useGetEmployeeStatementDetailsQuery,
    usePostEmployeeEditStatementMutation,
    useCloseSessionStatementMutation,
    useUpdateStatementGradeMutation,
    useUpdateStatementInfoMutation,
    useDeleteStudentFromStatementMutation,
} = statementApi;
