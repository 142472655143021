import {Card, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import PropTypes from "prop-types";

SessionScheduleCategory.propTypes = {
    categoryName: PropTypes.string.isRequired,
    disciplines: PropTypes.array.isRequired
}

export default function SessionScheduleCategory(props) {
    const groups = props.disciplines?.length > 0 ? props.disciplines[0].groups.map((g) => g.groupName) : [];
    
    return <>
        <Typography variant={"h5"}>{props.categoryName}</Typography>
        <Card>
            <Stack spacing={2}>
                <Table size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell rowSpan={2}>Наименование дисциплины</TableCell>
                            <TableCell rowSpan={2}>Ф.И.О. экзаменатора</TableCell>
                            <TableCell align={"center"} colSpan={groups?.length ?? 1}>Группа</TableCell>
                        </TableRow>
                        <TableRow>
                            {groups.map((g) => {
                                return <TableCell align={"center"}>{g}</TableCell>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.disciplines?.map((item) => {
                            return <TableRow>
                                <TableCell>{item.disciplineName}</TableCell>
                                <TableCell>
                                    {item.employees.join(', ')}
                                    {item.extraEmployees.length > 0 
                                        ? <><br />{item.extraEmployees.join(', ')}</> 
                                        : <></>}
                                </TableCell>

                                {item.groups.map((g) => {
                                    return <TableCell align={"center"}>{g.eventDate}<br />{g.eventPlace}</TableCell>
                                })}
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </Stack>
        </Card>
    </>
}