import {
    Button,
    Card,
    CardContent, CircularProgress,
    Container, Popover,
    Stack,
    Table, TableBody, TableCell, TableHead, TableRow,
    Typography
} from "@mui/material";
import {Helmet} from "react-helmet-async";
import React, {useRef, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DropdownButton from "../sections/@dashboard/common/DropdownButton";
import Scrollbar from "../components/scrollbar";
import JournalGradeSmall from "../sections/@dashboard/journal/JournalGradeSmall";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PropTypes from "prop-types";
import {useGetDisciplineGroupJournalBasicsQuery, useGetJournalByGroupQuery} from "../store/journal/journalApi";
import JournalComponent from "../components/journal/JournalComponent";
import {GeneralLayout} from "../layouts/GeneralLayout";
import './disciplinegroupjournal/index.scss';
import MainLayout from "./main";
import PageHeader from "../components/pageHeader";
import PageTitle from "../components/PageTitle";

export default function DisciplineGroupJournalPage() {
    const roles = localStorage.getItem("roles") ?? '';
    const isEmployee = roles.split(',').find((item) => item === "employee") !== undefined

    const {courseid, disciplineid} = useParams();
    const [journalTableVisible, setJournalTableVisible] = useState(false);
    const [selectedLessonType, setSelectedLessonType] = useState(-1);
    const [selectedGroup, setSelectedGroup] = useState(-1);

    const [groupFlowBindingId, setGroupFlowBindingId] = useState(null);
    const {data} = useGetDisciplineGroupJournalBasicsQuery({courseId: courseid, disciplineId: disciplineid});
    const [groupName, setGroupName] = useState('-');

    function copyObject(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    function groupSelectionChanged(index, type) {
        setJournalTableVisible(true);

        let teams = copyObject(type === 0
            ? data.lectureTeams
            : type === 1
                ? data.practicalTeams
                : type === 2
                    ? data.laboratoryTeams
                    : null);

        console.log(`teams before`, teams);
        teams = teams.sort((left, right) => left.groupName > right.groupName ? 1 : -1);
        console.log(`teams after`, teams);
        const groupFlowBindingEntity = teams[index];

        setGroupFlowBindingId(groupFlowBindingEntity.groupFlowBindingId);
        setSelectedLessonType(type);
        setSelectedGroup(index);

        // for test purposes only. Need to remove this line
        setGroupName(groupFlowBindingEntity.groupName);
    }

    const content = (
        <Stack padding={isEmployee ? "0px" : "100px 100px 0px 250px"}>
            <main id="journal">
                <section>
                    <div className="container">
                        <PageHeader
                            header={data?.disciplineName ?? 'Загрузка...'}
                            subheader={`Журнал посещаемости`}
                            backLink
                        />
                        <Stack padding="0px">

                            {data !== null && data !== undefined
                                ?
                                <Stack direction="row" justifyContent="start" spacing={2} mb={5}>
                                    <DropdownButton
                                        options={data.lectureTeams?.map((item) => item.groupName).sort((left, right) => left > right ? 1 : -1)}
                                        caption="Лекции"
                                        selectionChanged={(index) => groupSelectionChanged(index, 0)}/>

                                    <DropdownButton
                                        options={data.practicalTeams?.map((item) => item.groupName).sort((left, right) => left > right ? 1 : -1)}
                                        caption="Практические занятия"
                                        selectionChanged={(index) => groupSelectionChanged(index, 1)}/>

                                    <DropdownButton
                                        options={data.laboratoryTeams?.map((item) => item.groupName).sort((left, right) => left > right ? 1 : -1)}
                                        caption="Лабораторные занятия"
                                        selectionChanged={(index) => groupSelectionChanged(index, 2)}/>
                                </Stack>
                                : <></>
                            }
                        </Stack>
                        {(journalTableVisible ?
                            (
                                <JournalComponent
                                    groupFlowBindingId={groupFlowBindingId}
                                    groupName={groupName}/>
                            ) : (<></>))}
                    </div>
                </section>
            </main>
        </Stack>
    )

    return (
        isEmployee
            ? <MainLayout>
                <PageTitle title={`Журнал`} />
                {content}
            </MainLayout>
            : <GeneralLayout>
                <PageTitle title={`Журнал`} />
                {content}
            </GeneralLayout>
    );
}