import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {prepareAuthHeaders} from "../../utils/prepareAuthHeaders";

export const chairApi = createApi({
    reducerPath: 'chairApi',
    keepUnusedDataFor: 1,
    baseQuery: fetchBaseQuery({
        baseUrl: '/Chair',
        prepareHeaders: prepareAuthHeaders,
    }),
    endpoints: (builder) => ({
        listAllChairs: builder.query({
            query: () => ({
                url: `/ListAllChairs`,
                method: 'GET'
            }),
        }),
        subGroupsByDisciplineBinding: builder.query({
            query: ({courseId, disciplineId}) => ({
                url: `/SubGroupsByDisciplineBinding`,
                method: 'GET',
                params: {courseId, disciplineId}
            }),
        }),
        saveDisciplineSubgroups: builder.mutation({
            query: ({ data }) => ({
                url: `/SaveDisciplineSubgroups`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Post'],
        }),
        getCurators: builder.query({
            query: () => ({
                url: `/GetCurators`,
                method: 'GET'
            }),
        }),
        getEmployeesOfChair: builder.query({
            query: () => ({
                url: `/GetEmployeesOfChair`,
                method: 'GET'
            }),
        }),
        getJobsOfChair: builder.query({
            query: () => ({
                url: `/JobsOfChair`,
                method: 'GET'
            }),
        }),
        saveGroupCurators: builder.mutation({
            query: ({ data }) => ({
                url: `/SaveGroupCurators`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Post'],
        }),
    }),
});

export const {
    useListAllChairsQuery,
    useSubGroupsByDisciplineBindingQuery,
    useSaveDisciplineSubgroupsMutation,
    useGetCuratorsQuery,
    useGetJobsOfChairQuery,
    useSaveGroupCuratorsMutation
} = chairApi;
