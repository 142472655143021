import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import {
	Switch,
	Select,
	Button,
	MenuItem,
	IconButton,
	ToggleButtonGroup,
	ToggleButton,
	ThemeProvider,
	TextField,
} from "@mui/material";
import {
	AttachmentRounded,
	CloseRounded,
	DeleteRounded,
} from "@mui/icons-material";
import "./index.scss";
import toggleTheme from "../../../../components/toggleTheme";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import KVPair from "../../../../components/kv_pair";

function ValueModal(props) {
	dayjs.locale("ru");
	const [student, setStudent] = useState(null);
	const [lesson, setLesson] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const originalStudent = { ...props.student };

	useEffect(() => {
		setStudent(copyObject(props.student));
		setLesson(copyObject(props.day))
		console.log('value_modal.useEffect raised', props.day)
	}, [props.student, props.day]);

	function copyObject(obj) {
		return JSON.parse(JSON.stringify(obj));
	}

	const StyledSwitch = styled(Switch)(() => ({
		"& .MuiSwitch-switchBase": {
			"&.Mui-checked": {
				color: "#fff",
				"& + .MuiSwitch-track": {
					backgroundColor: "#00BFA5",
					opacity: 1,
				},
			},
		},
		"& .MuiSwitch-track": {
			backgroundColor: "#FF1744",
			opacity: 1,
			"&::before, &::after": {
				content: '""',
				position: "absolute",
				top: "50%",
				transform: "translateY(-50%)",
				width: 16,
				height: 16,
				backgroundSize: "contain",
			},
		},
		"& .MuiSwitch-thumb": {
			backgroundColor: "#D9D9D9",
		},
	}));

	const addEmptyButton = () => {
		if (lesson === null || lesson === undefined)
			return <></>;
		
		if (student) {
			const vals = lesson.grades;
			if (vals.length === 0) {
				return <Button onClick={addValue}>Добавить оценку</Button>;
			}
		}

		return <></>;
	};

	const closeModal = () => {
		props.closeHandler(originalStudent);
	};

	const setPresenceState = (e, checked) => {
		lesson.attended = checked;
		applyVisualChanges();
	};

	const saveStudent = () => {
		const newStudent = { ...student };
		setStudent(newStudent);
		props.saveHandler(student, lesson);
	};

	const handleValue = (e, newVal) => {
		if (newVal === null) return;
		const newStudent = { ...student };
		const newVals = lesson.grades;
		const currentVal = parseInt(e.target.dataset.value, 10);
		const val = newVals[currentVal];
		val.grade = newVal;
		lesson.grades = newVals;
		setStudent(newStudent);
	};

	const removeVal = (value, index) => {
		// const newStudent = { ...student };
		// const newVals = lesson.grades;
		value.deleted = true;
		// lesson.grades.splice(lesson.grades.indexOf(value), 1);
		// newVals.splice(newVals.indexOf(value), 1);
		// setStudent(newStudent);
		
		applyVisualChanges();
	};
	
	function applyVisualChanges() {
		setRefresh(!refresh);
	}

	const addValue = () => {
		const newStudent = { ...student };
		const newVal = {
			grade: 5,
			gradeType: 1,
			lessonStudentDetailsId: lesson.lessonStudentDetailsId,
			lessonStudentGradeId: 0
		};
		
		if (lesson.grades.length <= 3) {
			lesson.grades.push(newVal);
			setStudent(newStudent);
		}
	};

	const date = (type) => {
		if (student) {
			if (lesson !== null) {
				if (type === "date") {
					const stringDate = dayjs(lesson.lessonDate);
					return stringDate.format("D MMMM YYYY г.");
				}

				if (type === "class") return '';

				if (type === "values") 
					return lesson.grades;
			}
		}

		return "";
	};

	const handleVlaueType = (newVal, event) => {
		const newStudent = { ...student };
		const valIndex = event.props["data-val"];
		lesson.grades[valIndex].gradeType = newVal.target.value;
		setStudent(newStudent);
	};

	const values = () => {
		const vals = date("values");

		if (typeof vals === "object") {
			return vals.filter((item) => item.deleted === undefined || item.deleted === false || item.deleted === null)
				.map((value, index) => (
				<div className="value-wrapper" key={index}>
					<div className="value-type" data-val={index}>
						<Select
							onChange={handleVlaueType}
							label="Тип оценки"
							variant="standard"
							value={value.gradeType}
							sx={{ width: "100%" }}
						>
							<MenuItem data-val={index} value={1}>
								Тестирование
							</MenuItem>
							<MenuItem
								data-val={index}
								value={2}
							>
								Лабораторная работа
							</MenuItem>
							<MenuItem
								data-val={index}
								value={3}
							>
								Контрольная работа
							</MenuItem>
							<MenuItem
								data-val={index}
								value={4}
							>
								Расчётно-графическая работа
							</MenuItem>
							<MenuItem data-val={index} value={5}>
								Домашняя работа
							</MenuItem>
							<MenuItem data-val={index} value={6}>
								Другое
							</MenuItem>
						</Select>
					</div>
					<div className="value">
						<ThemeProvider theme={toggleTheme}>
							<ToggleButtonGroup
								sx={{ width: "100%" }}
								exclusive
								value={value.grade}
								onChange={handleValue}
							>
								<ToggleButton
									size="small"
									value={5}
									data-value={index}
									sx={{ flexGrow: 1 }}
								>
									5
								</ToggleButton>
								<ToggleButton
									size="small"
									value={4}
									data-value={index}
									sx={{ flexGrow: 1 }}
								>
									4
								</ToggleButton>
								<ToggleButton
									size="small"
									value={3}
									data-value={index}
									sx={{ flexGrow: 1 }}
								>
									3
								</ToggleButton>
								<ToggleButton
									size="small"
									value={2}
									data-value={index}
									sx={{ flexGrow: 1 }}
								>
									Неуд
								</ToggleButton>
							</ToggleButtonGroup>
						</ThemeProvider>
					</div>
					<div className="value-actions">
						<Button
							variant="text"
							sx={{ color: "red" }}
							data-value={index}
							onClick={() => removeVal(value)}
						>
							Удалить
						</Button>
						<Button
							variant="text"
							disabled={
								lesson.grades.length >= 3
							}
							onClick={addValue}
						>
							Добавить
						</Button>
					</div>
				</div>
			));
		}
		
		return <></>
	};

	const studentAbsent = (s) => {
		// const day = s.days[lesson];
		//
		// if (
		// 	(day.absent_reason === "" || day.absent_reason === null) &&
		// 	(day.absent_doc === "" || day.absent_doc === null) &&
		// 	(day.absent_link === "" || day.absent_link === null)
		// ) {
		// 	return "absent";
		// }
		//
		// return "soft-absent";
		return '';
	};

	const handleFile = (e, newVal) => {
		const file = e.target.files[0];
		const newStudent = { ...student };
		const day = newStudent.days[lesson];
		day.class = studentAbsent(newStudent);
		day.absent_link = file ? file.name : "Выберите файл";
		newStudent.days[lesson] = day;
		setStudent(newStudent);
	};

	const clearFile = () => {
		const newStudent = { ...student };
		const day = newStudent.days[lesson];
		day.class = studentAbsent(newStudent);
		day.absent_link = "";
		newStudent.days[lesson] = day;

		setStudent(newStudent);
	};

	const inputLabel = () => {
		const clearFileClass =
			student.days[lesson].absent_link === null ||
			student.days[lesson].absent_link === ""
				? "clear-file hide"
				: "clear-file";
		return (
			<label htmlFor="reason_file" className="file-wrapper">
				<div className="file-label">
					<AttachmentRounded />
					<span>
						{student.days[lesson].absent_link || "Выберите файл"}
					</span>
				</div>
				<div className="clear-wrapper">
					<IconButton onClick={clearFile} className={clearFileClass}>
						<DeleteRounded />
					</IconButton>
				</div>
			</label>
		);
	};

	const commentChange = (e) => {
		lesson.comment = e.target.value;
		applyVisualChanges()
	};

	const docnameChange = (e) => {
		const newStudent = { ...student };
		const day = newStudent.days[lesson];
		day.absent_doc = e.target.value;
		day.class = studentAbsent(newStudent);
		newStudent.days[lesson] = day;
		setStudent(newStudent);
	};

	const clearValues = () => {
		const newStudent = { ...student };
		newStudent.days[lesson].values = [];
		setStudent(newStudent);
	};

	const reason = () => {
		return (
			<div>
				<div className="reason-block">
					<TextField
						sx={{ width: "100%" }}
						onChange={commentChange}
						multiline
						variant="standard"
						value={lesson?.comment ?? ''}
						label="Комментарий"
					/>
				</div>
			</div>
		);
	};

	return (
		<div
			className={props.open ? "modal-wrapper open" : "modal-wrapper"}
			id="value-modal"
		>
			<div className="modal">
				<div className="modal-content">
					<div className="modal-header" style={{'display': 'flex', 'justify-content': 'space-between'}}>
						<div className="name">{student?.lastName} {student?.firstName} {student?.middleName}</div>
						<IconButton onClick={closeModal}>
							<CloseRounded />
						</IconButton>
					</div>
					<KVPair _key="Дата" _value={date("date")} />
					<KVPair
						_key="Присутствие"
						_value={
							<StyledSwitch
								checked={lesson?.attended}
								onChange={setPresenceState}
							/>
						}
					/>
					{addEmptyButton()}
					{values()}
					{reason()}
				</div>
				<div className="modal-footer" style={{'display': 'flex', 'justify-content': 'end'}}>
					<Button variant="text" onClick={saveStudent}>
						Сохранить
					</Button>
				</div>
			</div>
		</div>
	);
}

export default ValueModal;
