import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from '../../utils/prepareAuthHeaders';

export const studentApi = createApi({
    reducerPath: 'studentApi',
    keepUnusedDataFor: 1,
    baseQuery: fetchBaseQuery({
        baseUrl: '/Student',
        prepareHeaders: prepareAuthHeaders,
    }),
    endpoints: (builder) => ({
        getListSessionDisciplinesForEmployee: builder.query({
            query: ({ pattern }) => ({
                url: `/ListSessionDisciplinesForEmployee`,
                method: 'GET',
                params: { pattern },
            }),
        }),
        getStudentSemesters: builder.query({
            query: ({ studentId }) => ({
                url: `/StudentSemesters`,
                method: 'GET',
                params: { studentId },
            }),
        }),
        getStudentDetailsBySemester: builder.query({
            query: ({ studentId, semesterId }) => ({
                url: `/StudentDetailsBySemester`,
                method: 'GET',
                params: { studentId, semesterId },
            }),
        }),
        getStudentsByPattern: builder.query({
            query: ({ pattern }) => ({
                url: `/GetStudentsByPattern`,
                method: 'GET',
                params: { pattern },
            }),
        }),
    }),
});

export const {
    useGetListSessionDisciplinesForEmployeeQuery,
    useGetStudentSemestersQuery,
    useGetStudentDetailsBySemesterQuery,
    useGetStudentsByPatternQuery
} = studentApi;
