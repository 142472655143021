import {Helmet} from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer,
    CardContent,
    Button,
    CircularProgress,
    Box,
    TableBody,
    Table,
    TableRow,
    TableCell,
    ButtonGroup,
    CardHeader, Backdrop,
} from '@mui/material';

import {Link, useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import {StepByStepTable} from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import AddIcon from "@mui/icons-material/Add";
import StopIcon from "@mui/icons-material/Stop";
import {
    CheckBoxOutlined, CheckCircleOutline,
    Download,
    DownloadingOutlined,
    Grade,
    HourglassBottom,
    PrintOutlined,
    School,
    StopCircleRounded,
    Watch
} from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CheckIcon from "@mui/icons-material/Check";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import {
    useDownloadAttachmentsQuery,
    useGetPracticesOfGroupQuery,
    useSetNextApprovalStatusMutation
} from "../store/practice/practiceApi";
import Paper from "@mui/material/Paper";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import PageTitle from "../components/PageTitle";

export default function ManagePracticesForGroup() {
    const {groupId} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const [statementIsLoading, setStatementIsLoading] = useState(false);
    const {data} = useGetPracticesOfGroupQuery({groupId});
    const [selectedStatementId, setSelectedStatementId] = useState(3);
    // const downloadAttachments = useDownloadAttachmentsQuery({statementId: selectedStatementId});
    const [sendForApproval] = useSetNextApprovalStatusMutation();
    
    useEffect(() => {
        if (selectedStatementId === null)
            return;
        
        console.log("useEffect has started");
        // console.log(`Attachments query:`, downloadAttachments)
        // downloadAttachments.refetch().unwrap().then((fulfilled) => {
        //     console.log('Получили приложения')
        //     console.log(fulfilled);
        //     // const file = new Blob([fulfilled], { type: "application/zip" });
        //     // // Build a URL from the file
        //     // const fileURL = URL.createObjectURL(file);
        //     // // Open the URL on new Window
        //     // const pdfWindow = window.open();
        //     // pdfWindow.location.href = fileURL;
        //     setIsSaving(false);
        // }, (rejected) => {
        //     console.log('rejected');
        //     console.log(rejected)
        // }).catch((error) => {
        //     console.log(`Error`, error)
        // });
        
        
    }, [selectedStatementId])

    async function onDownloadAttachmentsClick(practiceStatementId) {
        setSelectedStatementId(practiceStatementId);
        setIsSaving(true);
        console.log(`Загружаем приложения для ведомости ${practiceStatementId}`);

        await fetch(`/Practice/DownloadAttachments?statementId=${practiceStatementId}`, {
            method: 'GET',
            headers: {
                'authorization': `Bearer ${localStorage.getItem("token")}`,
                'content-type': 'application/zip'
            }
        }).then((response) => {
            console.log(`Got a response: `, response);
            return response.blob()
        }).then((zipBlob) => {
            console.log(`Отображем файл пользователю`, zipBlob);
            const link = document.createElement('a');
            link.href = URL.createObjectURL(zipBlob);
            link.download = 'attachments.zip';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setIsSaving(false);
        })
    }
    
    function getStatementByPassingType(passingType, practice) {
        // passing types: 0 - normal, 1 - retake, 2 - commission
        const copyOfStatements = copyObject(practice.statements ?? []);
        
        const orderedStatements = copyOfStatements.sort((left, right) => {
            const leftParsed = new Date(left.creationDate);
            const rightParsed = new Date(right.creationDate);
            
            return leftParsed > rightParsed ? 1 : -1;
        });
        
        return orderedStatements[passingType];
    }

    function copyObject(source) {
        if (source === null || source === undefined)
            return {};

        return JSON.parse(JSON.stringify(source));
    }

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function toDateString(date) {
        if (date === null)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }


    async function sendPracticeForApproval(practiceId) {
        setIsSaving(true);
        await sendForApproval({practiceId}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                window.location.reload();
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });
            }

            setIsSaving(false);
        });
    }

    function downloadStatementDraft(statementId) {
        setStatementIsLoading(true);
        fetch(`/Reports/PracticeStatementDraft?statementId=${statementId}`,
            {
                method: "GET",
                headers: { "semesterid": localStorage.getItem("selectedSemesterId"), "Content-Type": "application/json", "Authorization": `Bearer ${localStorage.getItem('token')}`}
            }).then(response => {
            setStatementIsLoading(false);
            return response.blob();
        }).then(response => {
            const url = window.URL.createObjectURL(
                new Blob([response]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `Ведомость по практике.pdf`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        })
    }

    function printPracticeStatement(statementId) {
        setStatementIsLoading(true);
        fetch(`/Reports/PracticeStatement?statementId=${statementId}`,
            {
                method: "GET",
                headers: { "semesterid": localStorage.getItem("selectedSemesterId"), "Content-Type": "application/json", "Authorization": `Bearer ${localStorage.getItem('token')}`}
            }).then(response => {
            setStatementIsLoading(false);
            return response.blob();
        }).then(response => {
            const url = window.URL.createObjectURL(
                new Blob([response]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `Ведомость по практике.pdf`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        })
    }

    return (
        <GeneralLayout>
            <PageTitle title={`Список практик группы`} />

            <Container>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isSaving}
                >
                    <Stack alignItems={"center"}>
                        <CircularProgress color="inherit" />
                        <Typography>Загрузка...</Typography>
                        <Typography>Это займет некоторое время</Typography>
                    </Stack>
                </Backdrop>


                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress/> : <></>}
                            Список практик группы {data?.groupName}
                        </Typography>

                        <Button startIcon={<AddIcon/>}
                                variant={"contained"}
                                href={`/faculty-create-practice/${groupId}`}>Добавить практику</Button>
                    </Stack>

                    <Card>
                        <CardContent>
                            {data === null || data === undefined
                                ? <Typography align={"center"} fullWidth>Загрузка</Typography>
                                : <></>}

                            {data !== null && data !== undefined && data?.practices.length === 0
                                ? <Typography align={"center"} fullWidth>Список пуст</Typography>
                                : <></>}

                            <Table>
                                <TableBody>
                                    {data?.practices.map((practice) => {
                                        const primaryStatement = getStatementByPassingType(0, practice);
                                        const retakeStatement = getStatementByPassingType(1, practice);
                                        const commissionStatement = getStatementByPassingType(2, practice);
                                        return <>
                                            <TableRow>
                                                <TableCell>
                                                    <Stack spacing={1}>
                                                        <Typography variant={"h6"}>{practice.practiceType}</Typography>

                                                        {practice.approveStatus === 0
                                                            ? <Stack direction={"row"} spacing={1}>
                                                                <Typography color={"error"}
                                                                            fontSize={"small"}><StopCircleRounded/></Typography>
                                                                <Typography color={"error"} fontSize={"small"}>Не
                                                                    отправлено на утверждение</Typography>
                                                            </Stack>
                                                            : practice.approveStatus === 1
                                                                ? <Stack direction={"row"} spacing={1}>
                                                                    <Typography
                                                                        fontSize={"small"}><HourglassBottom/></Typography>
                                                                    <Typography fontSize={"small"}>Отправлено на
                                                                        утверждение</Typography>
                                                                </Stack>
                                                                : <Stack direction={"row"} spacing={1}>
                                                                    <Typography color={"green"}
                                                                                fontSize={"small"}><CheckCircleIcon/></Typography>
                                                                    <Typography color={"green"}
                                                                                fontSize={"small"}>Утверждено</Typography>
                                                                </Stack>}

                                                        <Stack direction={"row"} spacing={1}>
                                                            <Typography color={"gray"}
                                                                        fontSize={"small"}><School/></Typography>
                                                            <Typography color={"gray"}
                                                                        fontSize={"small"}>{practice.practiceKind === 1 ? 'Учебная' : 'Производственная'}</Typography>
                                                        </Stack>

                                                        <Stack direction={"row"} spacing={1}>
                                                            <Typography color={"gray"}
                                                                        fontSize={"small"}><Grade/></Typography>
                                                            <Typography color={"gray"}
                                                                        fontSize={"small"}>{practice.controlType === 1 ? 'Зачёт' : 'Зачёт с оценкой'}</Typography>
                                                        </Stack>

                                                        <Stack direction={"row"} spacing={1}>
                                                            <Typography color={"gray"}
                                                                        fontSize={"small"}><CalendarMonthIcon/></Typography>
                                                            <Typography color={"gray"} fontSize={"small"}>Сроки
                                                                проведения: {practice.timeSpans.map((ts) => {
                                                                    return `с ${ts.from} по ${ts.to}`
                                                                }).join(', ')}</Typography>
                                                        </Stack>

                                                        <Stack direction={"row"} spacing={1}>
                                                            <Typography color={"gray"}
                                                                        fontSize={"small"}><CheckCircleIcon/></Typography>
                                                            <Typography color={"gray"} fontSize={"small"}>Сроки защиты:
                                                                с {toDateString(practice.defenceFrom)} по {toDateString(practice.defenceTo)}</Typography>
                                                        </Stack>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align={"right"}>
                                                    <ButtonGroup orientation={"vertical"} disabled={isSaving}>
                                                        {practice.approveStatus === 0
                                                            ? <>
                                                                <Button startIcon={<CheckIcon/>}
                                                                        onClick={() => {
                                                                            sendPracticeForApproval(practice.practiceId)
                                                                        }}>Отправить на утверждение</Button>
                                                                <Button startIcon={<EditIcon/>}
                                                                        href={`/editpractice/${practice.practiceId}/${groupId}`}>Редактировать</Button>
                                                            </>
                                                            : <></>}
                                                        { /* <Button color={"error"} startIcon={<RemoveCircleIcon />}>Удалить практику</Button> */}
                                                    </ButtonGroup>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={2}>
                                                    <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
                                                        <Card variant={"outlined"}>
                                                            <Paper elevation={3} style={{padding: '10px', "background-color": '#2065D1'}} fullWidth>
                                                                <Typography fontWeight={800} align={"center"}color={"white"} fullWidth>Основная ведомость</Typography>
                                                            </Paper>
                                                            <CardContent>
                                                                <Stack spacing={3} alignItems={"center"}>
                                                                    <Typography align={'center'}>{primaryStatement === null || primaryStatement === undefined ? 'Ведомость не создана' : `${primaryStatement.closingDate === null ? 'Открытая' : 'Закрытая'} ведомость №${primaryStatement.statementNumber}`}</Typography>
                                                                    <ButtonGroup variant={"outlined"} size={"small"} orientation={"vertical"}>
                                                                        {primaryStatement === null || primaryStatement === undefined 
                                                                        ? <Button href={`create-practice-statement/${practice.practiceId}/${groupId}`}>Создать</Button>
                                                                        : <></>}

                                                                        {primaryStatement?.practiceStatementId > 0
                                                                        ? <>
                                                                            <Button startIcon={<InsertDriveFileOutlinedIcon />}
                                                                                    href={`/practice-statement-results/${primaryStatement.practiceStatementId}`}>Подробности и результаты</Button>
                                                                            {primaryStatement?.closingDate !== null && primaryStatement?.closingDate !== undefined
                                                                                ? <>
                                                                                    <Button startIcon={statementIsLoading ? <CircularProgress size={15} /> : <PrintOutlined />} 
                                                                                            disabled={statementIsLoading}
                                                                                            onClick={() => printPracticeStatement(primaryStatement.practiceStatementId)}>
                                                                                        Печать</Button>
                                                                                    <Button startIcon={<DownloadingOutlined />} onClick={() => onDownloadAttachmentsClick(primaryStatement.practiceStatementId)}>Скачать приложения</Button>
                                                                                    {primaryStatement.signed === null || primaryStatement.signed === undefined
                                                                                        ? <><Button startIcon={<CheckCircleOutline />} disabled>Сдать ведомость</Button></>
                                                                                        : <></>}
                                                                                </>
                                                                                : <><Button startIcon={<PrintOutlined />} onClick={()=>downloadStatementDraft(primaryStatement.practiceStatementId)}>Справочная ведомость</Button></>}
                                                                          </>
                                                                        : <></>}
                                                                    </ButtonGroup>
                                                                </Stack>
                                                            </CardContent>
                                                        </Card>

                                                        <Card variant={"outlined"}>
                                                            <Paper elevation={3} style={{padding: '10px', "background-color": '#fcf8e3'}}>
                                                                <Typography fontWeight={800} align={"center"} color={"#8a6d3a"} fullWidth>Дополнительная ведомость</Typography>
                                                            </Paper>
                                                            <CardContent>
                                                                <Stack spacing={3} alignItems={"center"}>
                                                                    <Typography align={'center'}>{retakeStatement === null || retakeStatement === undefined ? 'Ведомость не создана' : `${retakeStatement.closingDate === null ? 'Открытая' : 'Закрытая'} ведомость №${retakeStatement.statementNumber}`}</Typography>
                                                                    <ButtonGroup variant={"outlined"} size={"small"} orientation={"vertical"}>
                                                                        {(retakeStatement === null || retakeStatement === undefined) 
                                                                            && primaryStatement !== null && primaryStatement !== undefined 
                                                                            && primaryStatement.numberOfNotPassedStudents > 0 
                                                                            && primaryStatement.closingDate !== null && primaryStatement.closingDate !== undefined
                                                                            ? <Button href={`create-practice-statement/${practice.practiceId}/${groupId}`}>Создать</Button>
                                                                            : <></>}

                                                                        {retakeStatement?.practiceStatementId > 0
                                                                            ? <>
                                                                                <Button startIcon={<InsertDriveFileOutlinedIcon />}
                                                                                        href={`/practice-statement-results/${retakeStatement.practiceStatementId}`}>Подробности и результаты</Button>
                                                                                {retakeStatement?.closingDate !== null && retakeStatement?.closingDate !== undefined
                                                                                    ? <>
                                                                                        <Button startIcon={statementIsLoading ? <CircularProgress size={15} /> : <PrintOutlined />}
                                                                                                disabled={statementIsLoading}
                                                                                                onClick={() => printPracticeStatement(retakeStatement.practiceStatementId)}>
                                                                                            Печать</Button>
                                                                                        <Button startIcon={<DownloadingOutlined />} onClick={() => onDownloadAttachmentsClick(retakeStatement.practiceStatementId)}>Скачать приложения</Button>
                                                                                        {retakeStatement.signed === null || retakeStatement.signed === undefined
                                                                                            ? <><Button startIcon={<CheckCircleOutline />} disabled>Сдать ведомость</Button></>
                                                                                            : <></>}
                                                                                    </>
                                                                                    : <><Button startIcon={<PrintOutlined />} onClick={()=>downloadStatementDraft(retakeStatement.practiceStatementId)}>Справочная ведомость</Button></>}
                                                                            </>
                                                                            : <></>}
                                                                    </ButtonGroup>
                                                                </Stack>
                                                            </CardContent>
                                                        </Card>

                                                        <Card variant={"outlined"}>
                                                            <Paper elevation={3} style={{padding: '10px', "background-color": '#f5f5f5'}}>
                                                                <Typography fontWeight={800} align={"center"} color={"#333333"} fullWidth>Комиссионная ведомость</Typography>
                                                            </Paper>
                                                            <CardContent>
                                                                <Stack spacing={3} alignItems={"center"}>
                                                                    <Typography align={'center'}>{commissionStatement === null || commissionStatement === undefined ? 'Ведомость не создана' : `${commissionStatement.closingDate === null ? 'Открытая' : 'Закрытая'} ведомость №${commissionStatement.statementNumber}`}</Typography>
                                                                    <ButtonGroup variant={"outlined"} size={"small"} orientation={"vertical"}>
                                                                        {(commissionStatement === null || commissionStatement === undefined) 
                                                                            && retakeStatement !== null && retakeStatement !== undefined 
                                                                            && retakeStatement.numberOfNotPassedStudents > 0
                                                                            && retakeStatement.closingDate !== null && retakeStatement.closingDate !== undefined
                                                                            ? <Button href={`create-practice-statement/${practice.practiceId}/${groupId}`}>Создать</Button>
                                                                            : <></>}

                                                                        {commissionStatement?.practiceStatementId > 0
                                                                            ? <>
                                                                                <Button startIcon={<InsertDriveFileOutlinedIcon />}
                                                                                        href={`/practice-statement-results/${commissionStatement.practiceStatementId}`}>Подробности и результаты</Button>
                                                                                {commissionStatement?.closingDate !== null && commissionStatement?.closingDate !== undefined
                                                                                    ? <>
                                                                                        <Button startIcon={statementIsLoading ? <CircularProgress size={15} /> : <PrintOutlined />}
                                                                                                disabled={statementIsLoading}
                                                                                                onClick={() => printPracticeStatement(commissionStatement.practiceStatementId)}>
                                                                                            Печать</Button>
                                                                                        <Button startIcon={<DownloadingOutlined />} onClick={() => onDownloadAttachmentsClick(commissionStatement.practiceStatementId)}>Скачать приложения</Button>
                                                                                        {commissionStatement.signed === null || commissionStatement.signed === undefined
                                                                                            ? <><Button startIcon={<CheckCircleOutline />} disabled>Сдать ведомость</Button></>
                                                                                            : <></>}
                                                                                    </>
                                                                                    : <><Button startIcon={<PrintOutlined />} onClick={()=>downloadStatementDraft(commissionStatement.practiceStatementId)}>Справочная ведомость</Button></>}
                                                                            </>
                                                                            : <></>}
                                                                    </ButtonGroup>
                                                                </Stack>
                                                            </CardContent>
                                                        </Card>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    })}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}