// component
import SvgColor from '../../../components/svg-color';
import Nav from "./index";
import {useState} from "react";

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

let navConfig = [];
function fillNav() {
  const roles = localStorage.getItem("roles")?.split(',') ?? [];
  
  function isRole(name) {
    return roles.find((item) => item === name) !== undefined;
  }
  
  const isEmployee = isRole('employee');
  const isFaculty = isRole('faculty');
  const isChair = isRole('chair');
  const isUmu = isRole('AttNewUmu');
  const isDispatcher = isRole('AttNewDispatcher');
  console.log(`isEmployee: ${isEmployee}`);
  console.log(`isChair: ${isChair}`);
  console.log(`isFaculty: ${isFaculty}`);
  console.log(`isUmu: ${isUmu}`);
  console.log(`isDispatcher: ${isDispatcher}`);
  
  navConfig = [];
  
  if (isUmu) {
    navConfig.push({
      title: 'Учебно-методическое упр.',
      path: '/umu-main',
      icon: icon('ic_analytics'),
    });
  }

  if (isDispatcher) {
    navConfig.push({
      title: 'Диспетчерская',
      path: '/umu-main',
      icon: icon('ic_analytics'),
    });
  }

  if (isEmployee) {
    // navConfig.push({
    //   title: 'Главная',
    //   path: '/app',
    //   icon: icon('ic_analytics'),
    // });

    navConfig.push({
      title: 'Журнал',
      path: '/journalmainold',
      icon: icon('ic_user'),
    });

    navConfig.push({
      title: 'Аттестации',
      path: '/attestations',
      icon: icon('ic_analytics')
    });

    navConfig.push({
      title: 'Мои ведомости',
      path: '/mystatements',
      icon: icon('ic_academy'),
    });

    navConfig.push({
      title: 'Практики',
      path: '/my-practice-statements',
      icon: icon('ic_practices'),
    });
  }

  if (isFaculty) {
    navConfig.push({
      title: 'Журнал',
      path: '/journalmainold',
      icon: icon('ic_journal'),
    });

    navConfig.push({
      title: 'Аттестации',
      path: '/facultyattestations',
      icon: icon('ic_analytics'),
    });

    navConfig.push({
      title: 'Сессии',
      path: '/sessions',
      icon: icon('ic_academy'),
    });

    navConfig.push({
      title: 'Практики',
      path: '/faculty-practices-courses',
      icon: icon('ic_practices'),
    });

    navConfig.push({
      title: 'Карточка студента',
      path: '/search-student',
      icon: icon('ic_student'),
    });

    navConfig.push({
      title: 'Документы',
      path: '/documents',
      icon: icon('ic_document'),
    });
  }

  if (isChair) {
    navConfig.push({
      title: 'Журнал',
      path: '/journalmainold',
      icon: icon('ic_journal'),
    });

    navConfig.push({
      title: 'Кураторы',
      path: '/curators',
      icon: icon('ic_curator'),
    });
  }
  
  return navConfig;
}

export default fillNav;
