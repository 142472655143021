import CardBlock from "../cardBlock"
import TableBlock from "../tableBlock"
import { Grid } from "@mui/material"

/**
 * Блок детализации
 * @param {IProps} props Параметры блока детализации
 * @returns React.Node
 */
function DetailsBlock(props) {
  let component = null

  if (props.data !== null && props.data !== undefined && props.data.length > 0) {
    switch (props.view) {
      case "TABLE":
        component = <TableBlock data={props.data} title={props.title} />
        break
      case "CARD":
        component = <CardBlock data={props.data} title={props.title} />
        break
      default: component = <></>
    }
  } else {
    return <></>
  }

  return (
    <Grid item xl={4} lg={4}>
      {component}
    </Grid>
  )
}

export default DetailsBlock
