export const results = [
    {
        name: 'Операционные системы',
        type: 3,
        date: '10.06.2023',
        teachers: [{ name: 'Семенов Семен Семенович', id: 123 }],
        statement: {id: 1358, closed: true, dateOfClose: "12.06.2023" },
        grade: 4
    },
];
