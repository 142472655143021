import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer, CardContent, Button, CircularProgress, Box, Grid, Checkbox,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FileUploadButton from "../sections/@dashboard/common/FileUploadButton";
import {useGetStudentsByPatternQuery} from "../store/student/studentApi";
import SearchIcon from "@mui/icons-material/Search";
import {useSaveDocumentToRegistryMutation} from "../store/document/documentApi";
import PageTitle from "../components/PageTitle";

export default function CreateDocumentInRegistryPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const [fileUrl, setFileUrl] = useState(null);
    const [fileGuid, setFileGuid] = useState(null);
    const [document, setDocument] = useState({
        documentId: id,
        documentNumber: '',
        documentDate: null,
        fileGuid: null,
        fileUrl: null,
        studentId: null,
        semesters: []
    });
    const [refresh, setRefresh] = useState(false);
    let studentsPattern = '';
    const [studentsPatternQueryParam, setStudentsPatternQueryParam] = useState('');
    const [documentNumber, setDocumentNumber] = useState('');
    const [documentDate, setDocumentDate] = useState(``);
    const {data: availableStudents} = useGetStudentsByPatternQuery({pattern: studentsPatternQueryParam});
    const [studentSearchStarted, setStudentSearchStarted] = useState(false);
    const [saveDocumentRequest] = useSaveDocumentToRegistryMutation();
    
    useEffect(() => {
        setStudentSearchStarted(false);
    }, [availableStudents]);
    
    function applyVisualChanges() {
        setRefresh(!refresh);
    }

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function toDateString(date){
        if (date === null || date === undefined)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }
    
    function getSemestersCheckboxes(from, to) {
        const result = [];
        for (let i = from; i <= to; i+=1) {
            result.push(<FormControlLabel control={<Checkbox />} 
                                          label={`${i}-й`} style={{'marginLeft': 0}}
                                          onChange={(event, value) => handleSemesterSelect(i, value)}  />)
        }
        
        return result;
    }
    
    function handleSemesterSelect(semesterNumber, value) {
        const existingIndex = document.semesters.indexOf(semesterNumber);
        if (!value) {
            if (existingIndex >= 0) {
                document.semesters.splice(existingIndex, 1);
            }
        } else if (existingIndex < 0) {
            document.semesters.push(semesterNumber);
        }
        
        applyVisualChanges();
    }

    const handleSave = async () => {
        setIsSaving(true);

        await saveDocumentRequest({data: document}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                navigate('/registry-of-documents');
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });

                setIsSaving(false);
            }
        });
    };
    
    function pageTitle() {
        return `${(id > 0 ? `Редактировать` : `Создать`)} документ`;
    }
    
    function handleFileDelete() {
        document.fileGuid = null;
        document.fileUrl = null;
        setFileUrl(null);
        setFileGuid(null);
    }
    
    function handleFileUploaded(url, guid) {
        document.fileGuid = guid;
        document.fileUrl = url;
        setFileUrl(url);
        setFileGuid(guid);
    }
    
    function handleStudentPatternChanged(value) {
        studentsPattern = value;
        console.log(value);
        // applyVisualChanges();
    }
    
    function findStudent() {
        setStudentSearchStarted(true);
        setStudentsPatternQueryParam(studentsPattern);
    }
    
    function canSave() {
        // console.log('=====');
        // console.log(`document.documentDate !== null && document.documentDate !== undefined`, document.documentDate !== null && document.documentDate !== undefined);
        // console.log(`document.documentNumber !== null && document.documentNumber !== undefined`, document.documentNumber !== null && document.documentNumber !== undefined);
        // console.log(`document.studentId !== null && document.studentId !== undefined && document.studentId > 0`,document.studentId !== null && document.studentId !== undefined && document.studentId > 0);
        // console.log(`document.semesters.length > 0`, document.semesters.length > 0);
        // console.log(`document.fileGuid !== null && document.fileGuid !== undefined && document.fileGuid.length > 0`, document.fileGuid !== null && document.fileGuid !== undefined && document.fileGuid.length > 0);
        //
        // console.log(`documentdate`, document.documentDate)
        return document.documentDate !== null && document.documentDate !== undefined
            && document.documentNumber !== null && document.documentNumber !== undefined
            && document.studentId !== null && document.studentId !== undefined && document.studentId > 0
            && document.semesters.length > 0
            && document.fileGuid !== null && document.fileGuid !== undefined && document.fileGuid.length > 0;
    }

    return (
        <GeneralLayout>
            <PageTitle title={pageTitle()} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {document === null || document === undefined ? <CircularProgress /> : <></>}
                            {pageTitle()}
                        </Typography>
                    </Stack>

                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Grid container columnGap={3}>
                                    <Grid xs={5}>
                                        <Card>
                                            <CardContent>
                                                <Stack spacing={2}>
                                                    <Stack spacing={1}>
                                                        <Typography><strong>Номер документа</strong></Typography>
                                                        <TextField size={'small'} 
                                                                   placeholder={'Номер документа'}
                                                                   onInput={(event) => { setDocumentNumber(event.target.value); document.documentNumber = event.target.value; }} />
                                                    </Stack>

                                                    <Stack spacing={1}>
                                                        <Typography><strong>Дата документа</strong></Typography>
                                                        <TextField size={'small'} 
                                                                   type={'date'} 
                                                                   value={documentDate}
                                                                   onInput={(event) => {
                                                                       setDocumentDate(event.target.value);
                                                                       document.documentDate = event.target.value;
                                                                   }}
                                                                   placeholder={'Дата документа'}/>
                                                    </Stack>

                                                    <Stack spacing={1}>
                                                        <Typography><strong>Файл</strong></Typography>
                                                        <FileUploadButton maxFileSize={2.5 * 1024 * 1024}
                                                                          title={'Выбор файла'}
                                                                          align={'left'}
                                                                          uploadingEnabled
                                                                          existingFileGuid={fileGuid}
                                                                          existingFileUrl={fileUrl}
                                                                          onFileUploaded={(url, guid) => handleFileUploaded(url, guid)}
                                                                          onFileDelete={() => handleFileDelete()} />
                                                    </Stack>

                                                    <Stack spacing={1}>
                                                        <Typography><strong>Студент (поиск по ФИО)</strong></Typography>
                                                        <TextField size={'small'} 
                                                                   placeholder={'Введите ФИО студента'}
                                                                   onInput={(event) => handleStudentPatternChanged(event.target.value)}  />
                                                        <Button variant={'contained'} 
                                                                disabled={studentSearchStarted}
                                                                startIcon={studentSearchStarted ? <CircularProgress size={15}/> : <SearchIcon />}
                                                                onClick={()=> findStudent()}>Искать</Button>
                                                    </Stack>

                                                    <Stack spacing={1}>
                                                        <Typography>
                                                            <strong>Найденные студенты</strong>
                                                            {availableStudents?.students !== null && availableStudents !== undefined
                                                            ? ` (${availableStudents.students.length})`
                                                            : ``}
                                                        </Typography>
                                                        <Select size={'small'} onChange={(event) => {document.studentId = event.target.value; applyVisualChanges()}}>
                                                            {availableStudents?.students?.map((item) => {
                                                                return <MenuItem key={item.studentId} value={item.studentId}>{item.lastName} {item.firstName} {item.middleName} ({item.groupName}){item.isFired ? ' - отчислен(а)' : ''}</MenuItem>
                                                            })}
                                                        </Select>
                                                    </Stack>
                                                </Stack>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    
                                    <Grid xs={6}>
                                        <Stack spacing={1}>
                                            <Typography><strong>Семестры</strong></Typography>
                                            <Stack direction={'row'} spacing={2}>
                                                <Stack spacing={2}>
                                                    {
                                                        getSemestersCheckboxes(1, 6)
                                                    }
                                                </Stack>
                                                <Stack spacing={2}>
                                                    {
                                                        getSemestersCheckboxes(7, 11)
                                                    }
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                </Grid>

                                <Stack justifyContent="space-between">
                                    <Button variant="contained"
                                            disabled={!canSave() || isSaving}
                                            startIcon={isSaving ? <CircularProgress size={15} /> : <></>}
                                            onClick={handleSave}>Сохранить</Button>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}