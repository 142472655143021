import {useNavigate, Link} from "react-router-dom";
import {Button, ButtonGroup, TableCell, TableRow, Typography} from "@mui/material";
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import Groups3OutlinedIcon from '@mui/icons-material/Groups3Outlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import PropTypes from "prop-types";
import DisciplineBindingCard from "./DisciplineBindingCard";
import EditSubGroupsPage from "../../../pages/EditSubGroupsPage";
import {Edit} from "@mui/icons-material";

JournalListRow.propTypes = {
    disciplineName: PropTypes.string,
    courseName: PropTypes.string,
    lectures: PropTypes.array,
    seminars: PropTypes.array,
    laboratory: PropTypes.array,
    disciplineId: PropTypes.number,
    courseId: PropTypes.number,
    // id: PropTypes.number,
    editAction: PropTypes.func
}

export default function JournalListRow(props){
    const navigate = useNavigate();
    
    const roles = localStorage.getItem("roles")?.split(',') ?? [];
    const isEmployee = roles.find((item) => item === 'employee') !== undefined;
    const isChair = roles.find((item) => item === 'chair') !== undefined;
    const isAdmin = roles.find((item) => item === "AttNewAdmin") !== undefined;

    function copyObject(source) {
        if (source === null || source === undefined)
            return {};

        return JSON.parse(JSON.stringify(source));
    }
    
    function handleEditThemes(bindingId){
        navigate('/editthemes',
            {
                replace: true,
                state: {
                    id: bindingId
                },
            });
    }
    
    function handleEditSubGroups(bindingId){
        console.log(`Was made a click for binding`);
        console.log(bindingId);
        navigate('/editsubgroups',
            {
                replace: true,
                state: {
                    disciplineId: bindingId
                },
            });
    }

    function handleEditGroupJournal(bindingId){
        navigate('/disciplinegroupjournal',
            {
                replace: true,
                state: {
                    id: bindingId
                },
            });
    }
    
    function hasNotAssignedStudent() {
        if (!isEmployee) {
            return false;
        }
        
        const lab = props.laboratory.find((x) => x.studentsSet === false);
        const lect = props.lectures.find((x) => !x.studentsSet === false);
        const pract = props.seminars.find((x) => !x.studentsSet === false);
        
        return lab !== undefined && props.laboratory.length > 0
            || lect !== undefined && props.lectures.length > 0
            || pract !== undefined && props.seminars.length > 0;
    }
    
    function handleStudentSkipStatistic(bindingId) {
        console.log('test');
        alert('Раздел находится в разработке');
        // navigate('/studentskipstatistic',
        //     {
        //         replace: true,
        //         state: {
        //             id: bindingId
        //         },
        //     });
    }
    
    return (
        <TableRow>
            <TableCell>
                <strong>{props.disciplineName}</strong> <br />
                <small>{props.courseName}</small>
            </TableCell>
            <TableCell>
                {props.lectures.map((item) => {
                    return (
                        <DisciplineBindingCard bindingId={item.groupFlowTeamId} 
                                               groups={copyObject(item.groups)} 
                                               filledHours={item.filledHours} 
                                               totalHours={item.totalHours} 
                                               employeeName={item.employeeName}
                                               studentsSet={'true'}
                                               clickAction={() => props.editAction(item.groupFlowTeamId)} />
                    );
                })}
            </TableCell>
            <TableCell>
                {props.seminars.find((item) => item.hasVirtualGroups) !== undefined
                    ? <Typography fontSize={"small"} align={"center"}>Создана сводная группа</Typography>
                    : props.seminars.map((item) => {
                    return (
                        <DisciplineBindingCard bindingId={item.groupFlowTeamId} 
                                               groups={copyObject(item.groups)} 
                                               filledHours={item.filledHours} 
                                               totalHours={item.totalHours} 
                                               employeeName={item.employeeName}
                                               hasVirtualGroups={item.hasVirtualGroups}
                                               studentsSet={'true'}
                                               clickAction={() => props.editAction(item.groupFlowTeamId)} />
                    );
                })}
            </TableCell>
            <TableCell>
                {props.laboratory.find((item) => item.hasVirtualGroups) !== undefined
                ? <Typography fontSize={"small"} align={"center"}>Создана сводная группа</Typography>
                : props.laboratory.map((item) => {
                        return (
                            <DisciplineBindingCard bindingId={item.groupFlowTeamId}
                                                   groups={copyObject(item.groups)}
                                                   filledHours={item.filledHours}
                                                   totalHours={item.totalHours}
                                                   employeeName={item.employeeName}
                                                   studentsSet={item.studentsSetBySubgroups}
                                                   hasVirtualGroups={item.hasVirtualGroups}
                                                   clickAction={() => props.editAction(item.groupFlowTeamId)} />
                        );
                    })}
            </TableCell>
            <TableCell>
                <ButtonGroup orientation={"vertical"} size={"small"}>
                    { isEmployee
                        ?   <>
                                <Button disabled={() => true} onClick={()=> handleEditThemes(props.id)}><FormatListBulletedOutlinedIcon /> Список тем</Button>
                            </>
                        :   <></>
                    }
                        
                    <Button href={`/disciplinegroupjournal/${props.courseId}/${props.disciplineId}`}
                            disabled={hasNotAssignedStudent()}>
                        <AutoStoriesOutlinedIcon /> Журнал
                    </Button>

                    { isChair && isAdmin
                        ?
                        (<Button href={`/edit-bindings/${props.courseId}/${props.disciplineId}`}>
                            <Edit /> Изменить
                        </Button>)
                        : <></> }
    
                    { isEmployee
                        ?
                        (<Button href={`/editsubgroups/${props.courseId}/${props.disciplineId}`}
                                 disabled={props.laboratory.find((item) => item.hasVirtualGroups) !== undefined || props.seminars.find((item) => item.hasVirtualGroups) !== undefined}
                                color={hasNotAssignedStudent() ? 'error' : 'primary'}>
                        <Groups3OutlinedIcon /> Подгруппы
                    </Button>)
                        : <></> }
                    
                    { isEmployee
                        ?   <>
                                <Button disabled={() => true} onClick={() => handleStudentSkipStatistic(props.id)}><InsertDriveFileOutlinedIcon /> Свед. о пропусках студ-ов</Button>
                            </>
                        :   <></> }
                </ButtonGroup>
            </TableCell>
        </TableRow>  
    );
}