import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer, CardContent, Button, CircularProgress, Box, Table, TableBody,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import StudentGradeEditTable from "../sections/@dashboard/common/StudentGradeEditTable";
import {
    useEditPracticeStatementMutation,
    useEmployeePracticeStatementDetailsQuery
} from "../store/practice/practiceApi";
import MainLayout from "./main";
import PageTitle from "../components/PageTitle";

export default function EditPracticeStatementPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const {data} = useEmployeePracticeStatementDetailsQuery({id});
    const [copyOfData, setCopyOfData] = useState(null);
    const [saveData] = useEditPracticeStatementMutation();
    
    useEffect(() => {
        if (data === null || data === undefined)
            return;
        
        setCopyOfData(copyObject(data));
    }, [data]);

    function copyObject(source) {
        if (source === null || source === undefined)
            return null;

        return JSON.parse(JSON.stringify(source));
    }

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    function nameOfPracticeKind(practiceKind) {
        switch (practiceKind)
        {
            case 0: return 'Учебная';
            case 1: return 'Производственная';
            default: return '';
        }
    }

    function nameOfPassingType(passingType) {
        switch (passingType)
        {
            case 0: return 'основной';
            case 1: return 'дополнительной';
            case 2: return 'комиссионной';
            default: return '';
        }
    }

    function toDateString(date) {
        if (date === null || date === undefined)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    const handleSave = async () => {
        setIsSaving(true);
        
        const request = {
            practiceStatementId: id,
            students: copyOfData.statement.students.map((student) => {
                return {
                    studentId: student.studentId,
                    gradeId: student.grade,
                    attestationListGuid: student.attestationListGuid,
                    reportGuid: student.reportGuid,
                    documentsGuid: student.documentsGuid
                }
            })
        };

        await saveData({data: request}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                navigate('/my-practice-statements', {
                    replace: true
                });
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });

                setIsSaving(false);
            }
        });
    };

    return (
        <MainLayout>
            <PageTitle title={`Редактировать ведомость по практике`} />

            <Container>
                <Stack direction="column"
                       padding="30px 0px 0px 0px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {copyOfData === null || copyOfData === undefined ? <CircularProgress /> : <></>}
                            Редактирование {nameOfPassingType(copyOfData?.statement?.passingType)} ведомости №{copyOfData?.statement?.statementNumber} по практике
                        </Typography>
                    </Stack>

                    <Stack spacing={2}>
                        <Typography><strong>Вид, тип практики:</strong> {nameOfPracticeKind(copyOfData?.statement?.practiceKind)}, {copyOfData?.statement?.practiceType}</Typography>
                        <Typography><strong>Группа:</strong> {copyOfData?.statement?.groupName}</Typography>
                        <Typography><strong>Дата формирования:</strong> {toDateString(copyOfData?.statement?.creationDate)}</Typography>
                    </Stack>

                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <StudentGradeEditTable students={copyOfData?.statement?.students} useStatementsView={`true`} usePracticeView={`true`} controlType={copyOfData?.statement.controlTypeId}/>
                                
                                <Stack justifyContent="space-between">
                                    {
                                        !isSaving
                                            ? <Button variant="contained"
                                                      disabled={copyOfData === null || copyOfData === undefined}
                                                      onClick={handleSave}>Сохранить</Button>
                                            : <CircularProgress/>
                                    }
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
        </MainLayout>
    );
}