import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Box, Button,
    Card,
    CardContent, CircularProgress,
    Container,
    Grid,
    Link,
    Paper,
    Stack, Table, TableBody, TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {Download, PrintOutlined} from "@mui/icons-material";
import Scrollbar from "../components/scrollbar";
import StatementInfoBlock from "../sections/@dashboard/sessions/StatementInfoBlock";
import {GeneralLayout} from "../layouts/GeneralLayout";
import {useParams} from "react-router-dom";
import {useGetStatementInfoQuery, useGetStatementResultsQuery} from "../store/statement/statementApi";
import PracticeStatementInfoBlock from "../sections/@dashboard/practice/PracticeStatementInfoBlock";
import {usePracticeStatementResultsQuery} from "../store/practice/practiceApi";
import MainLayout from "./main";
import PageTitle from "../components/PageTitle";

export default function PracticeStatementResultsPage(props) {
    const {statementId} = useParams();
    const {data} = usePracticeStatementResultsQuery({id: statementId});
    const roles = localStorage.getItem('roles').split(',');
    const isEmployee = roles.find((item) => item === "employee") !== undefined;

    const content = (<>
        <PageTitle title={`Результаты ведомости по практике`} />

        <Container>
            <Stack
                padding={isEmployee ? "30px 0px 0px 0px" : "100px 0px 0px 100px"}
                direction="column"
                spacing={2}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {data === null || data === undefined ? <CircularProgress/> : <></>}
                        Результаты ведомости №{data?.statement?.statementNumber}
                    </Typography>
                </Stack>

                <PracticeStatementInfoBlock statement={data?.statement}
                                            printEnabled={data?.statement?.closingDate !== null}/>

                <br/>

                <Card>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>ФИО студента</TableCell>
                                <TableCell>Оценка</TableCell>
                                <TableCell>Аттестационный лист</TableCell>
                                <TableCell>Отчёт</TableCell>
                                <TableCell>Документы</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.statement?.students?.map((student, index) => {
                                return (
                                    <TableRow>
                                        <TableCell><Typography
                                            fontSize="x-large">{index + 1}</Typography></TableCell>
                                        <TableCell>
                                            <Typography>
                                                <strong>{student.lastName}</strong>
                                                <br/>{student.firstName} {student.middleName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                color={student.gradeId === 2 || student.gradeId === 7 || student.gradeId === 6
                                                    ? 'red'
                                                    : student.gradeId === 3
                                                        ? 'orange'
                                                        : 'green'}>
                                                {student.gradeName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {student.attestationList !== null
                                                ?
                                                <Button startIcon={<Download/>}
                                                        href={student.attestationList}
                                                        target={'_blank'}>Просмотреть</Button>
                                                : <></>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {student.report !== null
                                                ?
                                                <Button startIcon={<Download/>}
                                                        href={student.report}
                                                        target={'_blank'}>Просмотреть</Button>
                                                : <></>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {student.documents !== null
                                                ?
                                                <Button startIcon={<Download/>}
                                                        href={student.documents}
                                                        target={'_blank'}>Просмотреть</Button>
                                                : <></>
                                            }
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </Card>

                <Box height={100}/>
            </Stack>
        </Container>
    </>)

    return (
        isEmployee
            ?
            <GeneralLayout>
                {content}
            </GeneralLayout>
            : <MainLayout>
                {content}
            </MainLayout>
    )
}