import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {prepareAuthHeaders} from "../../utils/prepareAuthHeaders";

export const documentApi = createApi({
  reducerPath: 'documentApi',
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: '/Document',
    prepareHeaders: prepareAuthHeaders
  }),
  endpoints: (builder) => ({
    getRegistryDocuments: builder.query({
      query: () => ({
        url: `/GetRegistryDocuments`,
        method: 'GET',
      }),
    }),
    addToRegistry: builder.mutation({
      query: ({formData}) => ({
        url: `/AddToRegistry`,
        method: 'POST',
        body: formData
      }),
    }),
    getSkipDocuments: builder.query({
      query: () => ({
        url: `/GetSkipDocuments`,
        method: 'GET',
      }),
    }),
    deleteSkipDocument: builder.mutation({
      query: ({documentId}) => ({
        url: `/DeleteSkipDocument`,
        method: 'DELETE',
        params: {documentId}
      }),
    }),
    addSkipDocument: builder.mutation({
      query: () => ({
        url: `/AddSkipDocument`,
        method: 'POST',
      }),
    }),
    addCertificateOfSkipping: builder.mutation({
      query: ({data}) => ({
        url: `/AddCertificateOfSkipping`,
        method: 'POST',
        body: data
      }),
    }),
    getCertificatesOfSkipping: builder.query({
      query: ({groupId}) => ({
        url: `/GetCertificatesOfSkipping`,
        method: 'GET',
        params: {groupId}
      }),
    }),
    removeCertificateOfSkipping: builder.mutation({
      query: ({data}) => ({
        url: `/RemoveCertificateOfSkipping`,
        method: 'POST',
        body: data
      }),
    }),
    saveDocumentToRegistry: builder.mutation({
      query: ({data}) => ({
        url: `/SaveDocumentToRegistry`,
        method: 'POST',
        body: data
      }),
    }),
  }),
});

export const { 
  useGetRegistryDocumentsQuery, 
  useAddToRegistryMutation,
  useAddCertificateOfSkippingMutation,
  useGetCertificatesOfSkippingQuery,
  useRemoveCertificateOfSkippingMutation,
  useSaveDocumentToRegistryMutation,
} = documentApi;
